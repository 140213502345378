import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import _ from 'underscore';

// Model
import { OrderStepEvidenceModel } from '@/models/orderStepEvidenceModel';
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderEvidence } from '@/models/orderEvidenceModel';

@Component
export default class SelectOrder extends Vue {
  @Prop()
  private groupedStepOrders!: GroupStepEvidenceModel;

  @Prop()
  private noCheckboxes!: boolean;

  private orderList!: OrderStepEvidenceModel[];

  private process: string = '';

  private allSelected: boolean = true;

  private finalClient: string = '';

  private created(): void {
    this.orderList = _.uniq(this.groupedStepOrders.orderStyle, 'orderId');
    this.allSelected =
      this.groupedStepOrders.orderStyle.findIndex(
        (o) => o.isSelected === false
      ) === -1
        ? true
        : false;
    this.process = this.groupedStepOrders.steps.join(', ');
    if (this.orderList.length > 0) {
      const orders = this.$store.getters.orderEvidence as OrderEvidence[];
      this.finalClient = orders.filter(
        (o) => o.ID === this.orderList[0].orderId
      )[0].fromCompanyUser.name;
    }
  }

  private selectAll(): void {
    this.allSelected = !this.allSelected;
    this.orderList.forEach((o) => {
      o.isSelected = this.allSelected;
    });
    const orderList = this.orderList.filter((o) => o.isSelected === true);
    const selectedOrderList: OrderStepEvidenceModel[] = [];
    orderList.forEach((order) => {
      selectedOrderList.push(
        ...this.groupedStepOrders.orderStyle.filter(
          (o) => o.orderId === order.orderId
        )
      );
    });
    this.selectedOrderList(selectedOrderList);
  }

  private select(index: number, state: boolean): void {
    this.orderList[index].isSelected = !state;
    if (
      this.orderList.filter((o) => o.isSelected === true).length ===
      this.orderList.length
    ) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    const orderList = this.orderList.filter((o) => o.isSelected === true);
    const selectedOrderList: OrderStepEvidenceModel[] = [];
    orderList.forEach((order) => {
      selectedOrderList.push(
        ...this.groupedStepOrders.orderStyle.filter(
          (o) => o.orderId === order.orderId
        )
      );
    });
    this.selectedOrderList(selectedOrderList);
  }

  @Emit()
  private selectedOrderList(selectedOrders: OrderStepEvidenceModel[]): void {}
}
