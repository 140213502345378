import { ApplicationConfig } from '@/config';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { YarnFootprintRequestModel, YarnFootprintResponseModel, WetProcessFootprintResponseModel, YarnModel } from '@/models/yarnFootprintModel';
import { WetProcessFootprintRequestModel } from '@/models/wetProcessFootprintModel';
import { DeleteDocRequestModel, FootprintDataResponseModel, FootprintDeadlineResponseModel, SaveFootprintDeadlineModel, ViewFootprintResponseModel} from '@/models/footprintModel';
import { FabricFootprintRequestModel } from '@/models/fabricFootprintModel';
import { GarmentFootprintModel, GarmentFootprintRequestModel } from '@/models/garmentFootprintModel';
import { FootprintPartnerResponseModel } from '@/models/orderLineStepDisplayModel';
import { InvitedCompaniesModel } from '@/models/invitedCompaniesModel';
import { CompanyNameResponseModel } from '@/models/companyModel';

export class SupplierBlockchain extends BaseClientBlockchain {
    private readonly endPointOrder: string = "v1/api/order";
    private readonly endPoint: string = "v1/api";

    public constructor() {
        super(ApplicationConfig.endpoints.blockchainApi);
    }

    // Sprint 23 - TTD-3647
    public async getCompanyFromNameAsync(company: string): Promise<InvitedCompaniesModel>{
        return await this.get<InvitedCompaniesModel>(`${this.endPoint}/company/${company}/search`);
    }

    // Sprint 23 - TTD-3647
    public async getCompanyFromRegAsync(reg: string): Promise<InvitedCompaniesModel>{
        return await this.get<InvitedCompaniesModel>(`${this.endPoint}/company/regNumber/${reg}/search`);
    }

    // TTD-3934
    public async saveYarnFootprintDataAsync(orderId: string, data: YarnFootprintRequestModel): Promise<YarnFootprintResponseModel>{
        return await this.patchWithResponse<YarnFootprintResponseModel>(`${this.endPointOrder}/${orderId}/setYarnFootprint`, data, false);
    }

    // TTD-3934
    public async saveWetProcessDataAsync(orderId: string, data: WetProcessFootprintRequestModel): Promise<WetProcessFootprintResponseModel>{
        return await this.patchWithResponse<WetProcessFootprintResponseModel>(`${this.endPointOrder}/${orderId}/addWPFootprint`, data, false);
    }

    // TTD-3934
    public async uploadSupportingDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/supportingdocs`, uploadData, true);
    }

    // TTD-3934
    public async uploadWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/msdsdocs`, uploadData, true);
    }

    // TTD-3934
    public async getFootprintOnOrderAsync(orderId: string): Promise<ViewFootprintResponseModel>{
        return await this.get<ViewFootprintResponseModel>(`${this.endPointOrder}/${orderId}/getFootprint`);
    }

    // TTD-3934
    public async saveYarnTransportAsync(orderId: string, data: any): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addTransport`, data, false);
    }

    // TTD-3934
    public async deleteSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteSupportingDocs`, data);
    }

    // TTD-3934
    public async saveFabricFootprintDataAsync(orderId: string, data: FabricFootprintRequestModel): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addFabricFootprint`, data, false);
    }

    // TTD-3934
    public async deleteMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteMsdsDocs`, data);
    }

    // TTD-3934
    public async saveGarmentFootprintDataAsync(orderId: string, data: GarmentFootprintRequestModel): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addGarmentFootprint`, data, false);
    }

    // TTD-3934
    public async getFootprintPartnerAsync(orderId: string, lineId: string): Promise<FootprintPartnerResponseModel>{
        return await this.get<FootprintPartnerResponseModel>(`${this.endPointOrder}/${orderId}/getSCPAsscociatedWithSteps`);
    }

    // TTD-3934
    public async uploadYarnWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/msdsdocsYarnWp`, uploadData, true);
    }

    // TTD-3934
    public async deleteYarnWetMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteMsdsdocsYarnWp`, data);
    }

    // TTD-3934
    public async uploadSupportingWetYarnDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/supportingdocsYarnWp`, uploadData, true);
    }

    // TTD-3934
    public async deleteWetYarnSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteSupportingDocsYarnWp`, data);
    }

    // TTD-3691 get company name
    public async getCompanyNameAsync(): Promise<CompanyNameResponseModel> {
        return this.get(`${this.endPoint}/company/redis/getAllCompany`);
    }
}
