import ClickOutside from 'vue-click-outside';
import Multiselect from 'vue-multiselect';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import lodash from 'lodash';

import { DropdownModel } from '@/models/dropdownModel';

@Component({
  components: { Multiselect },
  directives: {
    ClickOutside,
  },
})
export default class MultiSelect extends Vue {
  @Prop()
  private options!: DropdownModel[];

  @Prop()
  private disabled!: boolean;

  @Prop()
  private invalid!: boolean;

  @Prop()
  private placeholder!: string;

  @Prop()
  private type!: string;

  @Prop()
  private index!: number;

  @Prop()
  private selectedValues?: string[] | number[];

  private selectedOptions: DropdownModel[] = [];

  private created(): void {
    if (this.selectedValues != null && this.selectedValues.length > 0) {
      this.selectedOptions = this.options.filter((option) =>
        this.selectedValues?.some((s: string | number) => s === option.value)
      );
    }
  }

  private isSelected(option: DropdownModel): void {
    const allSelectedValues = this.selectedOptions
      .map((option) => option.value)
      .concat(option.value);

    this.optionsSelected(lodash.uniq(allSelectedValues), this.type, this.index);
  }

  private isRemoved(option: DropdownModel): void {
    const allSelectedValues = this.selectedOptions
      .map((item) => item.value)
      .filter((item) => item !== option.value);

    this.optionsSelected(lodash.uniq(allSelectedValues), this.type, this.index);
  }

  private get label(): string {
    if (this.selectedOptions.length === 0) {
      return this.placeholder;
    } else if (this.selectedOptions.length <= 2) {
      return this.selectedOptions.map((s) => s.text).join(', ');
    } else {
      return this.$t('pages.profile.options_selected', [
        this.selectedOptions.length,
      ]).toString();
    }
  }

  @Emit()
  private optionsSelected(
    option: (string | number)[],
    type: string | null,
    index: number | null
  ): void {}
}
