import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

// State
import { IState } from './state';

// Models
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { UserModel } from '@/models/userModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { OrderModel } from '@/models/orderModel';
import { CompanyModel, CompanyNameModel } from '@/models/companyModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { Actions } from './actions/actions';
import { CertificateModel } from '@/models/certificateModel';
import { OrderEvidence } from '@/models/orderEvidenceModel';
import { EvidenceStepCountModel, OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { AddressModel } from '@/models/addressModel';
import { UserRolePermissionsModel } from '@/models/permissionModel';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';

Vue.use(Vuex);

export default new Vuex.Store<IState>({
  state: {
    showMenuBar: true,
    showTopBar: true,
    isAppOnline: window.navigator.onLine,
    activeMenuItem: 'dashboard',
    selectedLanguage: 'en',
    user: null,
    isOrderLoading: false,
    orders: [],
    company: null,
    orderLineSteps: [],
    styleCount: 0,
    certificatesCount: 0,
    expiringCertCount: 0,
    isSupplierLoading: false,
    suppliers: [],
    pendingOrders: [],
    pendngOrderLineSteps: [],
    isPendingOrdersLoading: false,
    profile: null,
    confirmOrderLine: false,
    complianceCertificateLoading: false,
    complianceCertificates: [],
    orderLineEvidenceStepsLoading: false,
    orderEvidence: [],
    orderLineEvidenceSteps: [],
    orderLineEvidenceNeighbourSteps: [],
    orderLineEvidenceApproveSteps: [],
    savedOfficeAddresses: [],
    savedFactoryAddresses: [],
    canNavigate: true,
    userRole: '',
    userPermissions: null,
    encryptPwd: '',
    accessToken: null,
    evidenceCountLoading: false,
    uploadEvidenceCount: 0,
    approvedEvidenceCount: 0,
    socialCertificate: [],
    sustainableCertificate: [],
    footprintCount: 0,
    delegatedCount: 0,
    footprintOrders: [],
    isFootprintChanged: '',
    changedFootprintName: '',
    showFootprintConfirmation: '',
    incorrectFootprintData: false,
    readOrSignDocumentsCount: 0,
    expiredCertificateCount: 0,
    companyNameList: []
  },
  mutations: {
    // eslint-disable-next-line
    setOrderLoading(state: IState, orderLoading: boolean): void {
      state.isOrderLoading = orderLoading;
    },
    setCanNavigate(state: any, status: boolean): void {
      state.canNavigate = status;
    },
    // eslint-disable-next-line
    setOrders(state: IState, orders: OrderLineDisplayModel[]): void {
        state.orders = orders;
    },
    // eslint-disable-next-line
    setPendingOrders(state: any, pendingOrders: OrderLineDisplayModel[]): void {
      state.pendingOrders = pendingOrders;
    },
    // eslint-disable-next-line
    setOrderLineSteps(
      state: IState,
      orderLineSteps: OrderLineStepDisplayModel[]
    ): void {
        state.orderLineSteps = orderLineSteps;
    },
    // eslint-disable-next-line
    setPendingOrderLineSteps(
      state: IState,
      pendngOrderLineSteps: OrderLineStepDisplayModel[]
    ): void {
      state.pendngOrderLineSteps = pendngOrderLineSteps;
    },
    // eslint-disable-next-line
    setMyAccount(state: IState, company: CompanyModel): void {
      if (company !== null) {
        if (company.officeAddress.length > 0) {
          company.officeAddress.forEach((office) => {
            if (office.geoLat !== 0 && office.geoLong !== 0) {
              office.isConfirmed = true;
            }
          });
        }
        if (company.factoryAddresses.length > 0) {
          company.factoryAddresses.forEach((factory) => {
            if (factory.geoLat !== 0 && factory.geoLong !== 0) {
              factory.isConfirmed = true;
            }
          });
        }
      }
      state.company = company;
    },
    // eslint-disable-next-line
    setStyleCount(state: IState, count: number): void {
      let clonedStyleCount = _.cloneDeep(state.styleCount);
      clonedStyleCount = clonedStyleCount + count;
      state.styleCount = clonedStyleCount;
    },
    // eslint-disable-next-line
    setExpiringCertCount(state: IState, count: number): void {
      let clonedExpiringCertCount = _.cloneDeep(state.expiringCertCount);
      clonedExpiringCertCount = clonedExpiringCertCount + count;
      state.expiringCertCount = clonedExpiringCertCount;
    },
    // eslint-disable-next-line
    setUser(state: any, user: UserModel): void {
      state.user = user;
    },
    // eslint-disable-next-line
    setCertificatesCount(state: any, count: number): void {
      state.certificatesCount = count;
    },
    // eslint-disable-next-line
    setDelegatedCount(state: IState, count: number): void {
      let clonedDelegatedCount = _.cloneDeep(state.delegatedCount);
      clonedDelegatedCount = clonedDelegatedCount + count;
      state.delegatedCount = clonedDelegatedCount;
    },
    // eslint-disable-next-line
    setSuppliers(state: any, suppliers: OrderCompanyModel[]): void {
      state.suppliers = suppliers;
    },
    // eslint-disable-next-line
    setPendingOrderLineLoading(
      state: IState,
      isPendingOrderLoading: boolean
    ): void {
      state.isPendingOrdersLoading = isPendingOrderLoading;
    },
    // eslint-disable-next-line
    setProfile(state: any, profile: CompanyModel): void {
      state.profile = profile;
    },
    // eslint-disable-next-line
    setConfirmOrderLine(state: any, confirmOrderLine: boolean): void {
      state.confirmOrderLine = confirmOrderLine;
    },
    // eslint-disable-next-line
    setInitailState(state: any): void {
      (state.showMenuBar = true),
        (state.showTopBar = true),
        (state.isAppOnline = window.navigator.onLine),
        (state.activeMenuItem = 'dashboard'),
        (state.selectedLanguage = 'en'),
        (state.user = null),
        (state.isOrderLoading = false),
        (state.orders = []),
        (state.company = null),
        (state.orderLineSteps = []),
        (state.styleCount = 0),
        (state.certificatesCount = 0),
        (state.expiringCertCount = 0),
        (state.isSupplierLoading = false),
        (state.suppliers = []),
        (state.pendingOrders = []),
        (state.pendngOrderLineSteps = []),
        (state.isPendingOrdersLoading = false),
        (state.profile = null),
        (state.confirmOrderLine = false),
        (state.complianceCertificateLoading = false),
        (state.complianceCertificates = []),
        (state.orderLineEvidenceStepsLoading = false),
        (state.orderEvidence = []),
        (state.orderLineEvidenceSteps = []),
        (state.orderLineEvidenceNeighbourSteps = []),
        (state.orderLineEvidenceApproveSteps = []),
        (state.savedOfficeAddresses = []),
        (state.canNavigate = true),
        (state.savedFactoryAddresses = []),
        (state.encryptPwd = ''),
        (state.accessToken = null),
        (state.evidenceCountLoading = false),
        (state.uploadEvidenceCount = 0),
        (state.approvedEvidenceCount = 0),
        (state.socialCertificate = []),
        (state.isFootprintChanged = ''),
        (state.showFootprintConfirmation = ''),
        (state.changedFootprintName = ''),
        (state.sustainableCertificate = []);
        //TTD-2736 sprint-19 show foot print orders count
        (state.footprintCount = 0);
        (state.delegatedCount = 0);
        (state.changedFootprintName = '');
        (state.showFootprintConfirmation = '');
        (state.footprintOrders = []);
        (state.incorrectFootprintData = false);
        (state.readOrSignDocumentsCount = 0);
        (state.expiredCertificateCount = 0);
        (state.companyNameList = []);
    },
    // eslint-disable-next-line
    setComplainceCertificateLoading(
      state: IState,
      complianceCertificateLoading: boolean
    ): void {
      state.complianceCertificateLoading = complianceCertificateLoading;
    },
    // eslint-disable-next-line
    setComplianceCertificates(
      state: IState,
      complainceCertificates: CertificateModel[]
    ): void {
      state.complianceCertificates = complainceCertificates;
    },
    // eslint-disable-next-line
    setOrderLineEvidenceStepsLoading(state: IState, isLoading: boolean): void {
      state.orderLineEvidenceStepsLoading = isLoading;
    },
    // eslint-disable-next-line
    setOrderEvidence(state: IState, orders: OrderEvidence[]): void {
      state.orderEvidence = orders;
    },
    // eslint-disable-next-line
    setOrderLineEvidenceSteps(
      state: IState,
      orderLineEvidenceSteps: OrderLineEvidenceStep[]
    ) {
      state.orderLineEvidenceSteps = orderLineEvidenceSteps;
    },
    // eslint-disable-next-line
    setOrderLineEvidenceNeighbourSteps(
      state: IState,
      orderLineEvidenceNeighbourSteps: OrderLineEvidenceStep[]
    ) {
      state.orderLineEvidenceNeighbourSteps = orderLineEvidenceNeighbourSteps;
    },
    // eslint-disable-next-line
    setOrderLineEvidenceApproveSteps(
      state: IState,
      orderLineEvidenceApproveSteps: OrderLineEvidenceStep[]
    ) {
      state.orderLineEvidenceApproveSteps = orderLineEvidenceApproveSteps;
    },
    // eslint-disable-next-line
    setSavedOfficeAddresses(state: IState, officeAddresses: AddressModel[]) {
      state.savedOfficeAddresses = officeAddresses;
    },
    // eslint-disable-next-line
    setSavedFactoryAddresses(state: IState, factoryAddresses: AddressModel[]) {
      state.savedFactoryAddresses = factoryAddresses;
    },
    setUserRolePermissions(
      state: IState,
      userRolePermissions: UserRolePermissionsModel
    ): void {
      if (userRolePermissions !== null) {
        state.userRole = userRolePermissions.groupName;
        state.userPermissions = userRolePermissions.permissions;
      } else {
        state.userRole = '';
        state.userPermissions = null;
      }
    },
    setEncryptedPwd(state: IState, encryptedPwd: string): void {
      state.encryptPwd = encryptedPwd;
    },
    setAccessToken(state: IState, accessToken: BlockchainAccessModal): void {
      state.accessToken = accessToken;
    },
    setAllStyleCount(state: IState, count: number): void {
      state.styleCount = count;
    },
    setOrderLineEvidenceCountLoading(state: IState, isLoading: boolean): void {
      state.evidenceCountLoading = isLoading;
    },
    setOrderLineEvidenceCount(state: IState, evidenceCount: EvidenceStepCountModel): void {
      state.uploadEvidenceCount = evidenceCount.orderLineSteps;
      state.approvedEvidenceCount = evidenceCount.approvedSteps;
    },
    setSocialCertificates(state: IState, socialCertificate: CertificateModel[]): void {
      state.socialCertificate = socialCertificate;
    },
    setSustainableCertificates(state: IState, sustainableCertificate: CertificateModel[]): void {
      state.sustainableCertificate = sustainableCertificate;
    },
    //TTD-2736 sprint-19 show foot print orders count
    setFootprintCount(state: IState, footprintCount: number): void{
      state.footprintCount = footprintCount;
    },
    //TTD-2736 set foot print orders in store
    setFootprintOrders(state: any, footprintOrders: OrderLineDisplayModel[]): void {
      state.footprintOrders = footprintOrders;
    },
    setFootprintChanged(state: IState, isFootprintChanged: string): void {
      state.isFootprintChanged = isFootprintChanged;
    },
    setChangedFootprintName(state: IState, changedFootprintName: string): void {
      state.changedFootprintName = changedFootprintName;
    },
    setFootprintConfirmation(state: IState, showFootprintConfirmation: string): void {
      state.showFootprintConfirmation = showFootprintConfirmation;
    },
    setAllOrders(state: IState, orders: OrderLineDisplayModel[]): void {
      state.orders = orders;
    },
    //Sprint-22 TTD-4290
    setIncorrectFootprintData(state: IState, incorrectFootprintData: boolean): void {
      state.incorrectFootprintData = incorrectFootprintData;
    },
    // Sprint-24 TTD-3798
    setReadOrSignDocumentsCount(state: IState, readOrSignDocumentsCount: number): void {
      state.readOrSignDocumentsCount = readOrSignDocumentsCount;
    },
    setExpiredCertificateCount(state: IState, expiredCertificateCount: number): void{
      let clonedExpiringCertCount = _.cloneDeep(state.expiredCertificateCount);
      clonedExpiringCertCount = clonedExpiringCertCount + expiredCertificateCount;
      state.expiredCertificateCount = clonedExpiringCertCount;
    },
    setCompanyNameList(state: IState, companyName: CompanyNameModel[]): void {
      state.companyNameList = companyName;
    }
  },
  getters: {
    // eslint-disable-next-line
    isOrderLoading: (state) => {
      return state.isOrderLoading;
    },
    // eslint-disable-next-line
    orders: (state) => {
      return state.orders;
    },
    canNavigate: (state) => {
      return state.canNavigate;
    },
    userRole: (state) => {
      return state.userRole;
    },
    // eslint-disable-next-line
    company: (state) => {
      return state.company;
    },
    // eslint-disable-next-line
    ordersCount: (state) => {
      return state.orders.length;
    },
    // eslint-disable-next-line
    orderLineSteps: (state) => {
      return state.orderLineSteps;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    user: (state) => {
      return state.user;
    },
    // eslint-disable-next-line
    styleCount: (state) => {
      return state.styleCount;
    },
    // eslint-disable-next-line
    certificatesCount: (state) => {
      return state.certificatesCount;
    },
    // eslint-disable-next-line
    suppliers: (state) => {
      return state.suppliers;
    },
    // eslint-disable-next-line
    pendingOrders: (state) => {
      return state.pendingOrders;
    },
    // eslint-disable-next-line
    pendingOrderLineSteps: (state) => {
      return state.pendngOrderLineSteps;
    },
    // eslint-disable-next-line
    isPendingOrdersLoading: (state) => {
      return state.isPendingOrdersLoading;
    },
    // eslint-disable-next-line
    profile: (state) => {
      return state.profile;
    },
    // eslint-disable-next-line
    confirmOrderLine: (state): boolean => {
      return state.confirmOrderLine;
    },
    // eslint-disable-next-line
    complianceCertificateLoading: (state) => {
      return state.complianceCertificateLoading;
    },
    // eslint-disable-next-line
    complianceCertificates: (state) => {
      return state.complianceCertificates;
    },
    // eslint-disable-next-line
    orderLineEvidenceStepsLoading: (state) => {
      return state.orderLineEvidenceStepsLoading;
    },
    // eslint-disable-next-line
    orderEvidence: (state) => {
      return state.orderEvidence;
    },
    // eslint-disable-next-line
    orderLineEvidenceSteps: (state) => {
      return state.orderLineEvidenceSteps;
    },
    // eslint-disable-next-line
    orderLineEvidenceNeighbourSteps: (state) => {
      return state.orderLineEvidenceNeighbourSteps;
    },
    // eslint-disable-next-line
    orderLineEvidenceApproveSteps: (state) => {
      return state.orderLineEvidenceApproveSteps;
    },
    // eslint-disable-next-line
    savedOfficeAddresses: (state) => {
      return state.savedOfficeAddresses;
    },
    // eslint-disable-next-line
    savedFactoryAddresses: (state) => {
      return state.savedFactoryAddresses;
    },
    // eslint-disable-next-line
    userPermissions: (state) => {
      return state.userPermissions;
    },
    encryptPwd: (state) => {
      return state.encryptPwd;
    },
    accessToken: (state) => {
      return state.accessToken;
    },
    evidenceCountLoading: (state) => {
      return state.evidenceCountLoading;
    },
    uploadEvidenceCount: (state) => {
      return state.uploadEvidenceCount;
    },
    delegatedOrdersCount: (state) => {
      return state.delegatedCount;
    },
    approvedEvidenceCount: (state) => {
      return state.approvedEvidenceCount;
    },
    socialCertificate: (state) => {
      return state.socialCertificate;
    },
    sustainableCertificate: (state) => {
      return state.sustainableCertificate;
    },
    //TTD-2736 sprint-19 show foot print orders count
    footprintCount: (state) => {
      return state.footprintCount;
    },
    //TTD-2736 get foot print orders
    footprintOrders: (state) => {
      return state.footprintOrders;
    },
    isFootprintChanged: state => {
      return state.isFootprintChanged;
    },
    // Sprint-24 TTD-3798
    readOrSignDocumentsCount: (state) => {
      return state.readOrSignDocumentsCount;
    },
    expiredCertificateCount: (state) => {
      return state.expiredCertificateCount;
    },
    companyNameList: (state) => {
      return state.companyNameList;
    }
  },
  actions: {
    setOrderLines: Actions.setOrderLines,
    setPendingOrderLineLoading: Actions.setPendingOrderLineLoading,
    setMyAccount: Actions.setMyAccount,
    setComplianceCertificates: Actions.setComplianceCertificates,
    setOrderLineEvidenceSteps: Actions.setOrderLineEvidenceSteps,
    setOrderLineEvidenceCount: Actions.setOrderLineEvidenceCount,
    setUserRolePermissions: Actions.setUserRolePermissions,
    setReadOrSignDocs: Actions.setReadOrSignDocs,
    setCompanyNameList: Actions.setCompanyNameList
  },
  modules: {},
});
