import { AddressStatus } from './addressState';
import { Guid } from './guid';

export class AddressModel {
  public id!: string;
  public line1!: string;
  public line2!: string;
  public city!: string;
  public state!: string;
  public country!: string;
  public countryInFull!: string;
  public postal!: string;
  public officeName!: string;
  public disabled!: boolean;
  public forceEnabled!: boolean;
  public lookupAvailable!: boolean;
  public isOfficeAddress!: boolean;
  public countryError!: boolean;
  public stateError!: boolean;
  public cityError!: boolean;
  public isConfirmed!: boolean;
  public confirmError!: boolean;
  public isEdited!: boolean;
  public geoLat!: number;
  public geoLong!: number;
  public sequence!: number;
  public isConfirming!: boolean;
  public addressStatus!: string;
  public companySubtypes!: number[];

  public constructor() {
    this.id = Guid.Empty;
    this.line1 = '';
    this.line2 = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.countryInFull = '';
    this.postal = '';
    this.officeName = '';
    this.disabled = false;
    this.forceEnabled = false;
    this.geoLat = 0;
    this.geoLong = 0;
    this.sequence = 0;
    this.isOfficeAddress = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.confirmError = false;
    this.isEdited = false;
    this.isConfirmed = false;
    this.lookupAvailable = false;
    this.isConfirming = false;
    this.addressStatus = AddressStatus.ENABLE;
    this.companySubtypes = [];
  }
}
