import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// Models
import { UserModel } from '@/models/userModel';

// Services
import { UserClient } from '@/clients/userClient';

@Component({
  computed: {
    ...mapGetters(['userRole']),
  },
})
export default class Sidebar extends Vue {
  @Prop()
  private sidebar!: boolean;

  private isCollapsed: boolean = false;

  private applicationLogo: NodeRequire = require('../../../assets/logo.svg');

  private applicationLogoShort: NodeRequire = require('../../../assets/logo-short.svg');

  private client: UserClient = new UserClient();

  private userRole!: string;

  public constructor() {
    super();
  }

  private async created(): Promise<void> {
    this.isCollapsed =
      localStorage.getItem('collapsedSidebar') == 'true' ? true : false;
  }

  private get showOrderLinesCheck(): boolean {
    const user = this.$store.getters.user as UserModel;
    let show: boolean = false;
    if (user && (user.isT1Scp || user.isDelegatePartner)) {
      show = true;
    }
    return show;
  }

  private get isAdmin(): boolean {
    let result = true;
    if (this.userRole === 'Editor' || this.userRole === 'Reader') {
      result = false;
    } else {
      result = true;
    }
    return result;
  }
}
