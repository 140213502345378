import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';

// Components

// Model
import { TCStatus, TCType, TCtableModel } from "@/models/tcTableModel";
import { UserModel } from '@/models/userModel';
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderListModel, TCMailModel, UploadOrderModel, TCDocumentModel } from "@/models/transcationCertificateModal";
import moment from "moment";

// Services
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';
import { SupplierService } from "@/services/supplierService";

//Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

@Component({
    components: { VuePdfApp}
})

export default class ViewTcCertificates extends Vue {

    @Prop()
    private document!: any;

    @Prop()
    private showRSP!: boolean;

    @Prop()
    private uploadCertificate!: any;

    @Prop()
    private selectedOrders !: UploadOrderModel[];

    @Prop()
    private documentDetails!: TCDocumentModel;

    @Prop()
    private selectedType!: string;

    @Prop()
    private rejectedReason!: string;

    private orderList: OrderListModel[] = [];

    private certificateService: ComplianceCertificatesService;

    private supplierService: SupplierService;

    private viewDocument:TCDocumentModel = new TCDocumentModel();

    private pdfDocument!: any;
    private isPdfReady: boolean = false;
    private isDocUploading: boolean = false;
    private isSelectedDraft: boolean = false;
    private isSelectedFTC: boolean = false;
    private tcRefNumber: string = '';
    private showErrorMsg: boolean = false;
    private isTCrefEmpty: boolean = false;
    private isDocumentUploaded: boolean = false;
    private config: any = {
        toolbar: false
    };

    public constructor() {
        super();
        this.certificateService = new ComplianceCertificatesService();
        this.supplierService = new SupplierService();
    }

    private async created(): Promise<void> {
       if(this.selectedType === 'VIEWTC'){
        this.viewDocument.docUrl = this.documentDetails.docUrl;
        this.isSelectedDraft = this.documentDetails.tcData.tcType === 'DRAFT' ? true : false;
        this.isSelectedFTC = this.documentDetails.tcData.tcType === 'FINAL' ? true : false;
        this.tcRefNumber = this.documentDetails.tcData.tcReferenceNumber;
        // this.isDocumentUploaded = this.documentDetails.tcData.tcStatus === TCStatus.UPLOAD || this.documentDetails.tcData.tcStatus === TCStatus.REJECTED ? true : false;
       }else if(this.selectedType === 'UPLOADFINALTC'){
        this.viewDocument.docUrl = this.document;
        this.isSelectedFTC = true;
       }else{
        this.viewDocument.docUrl = this.document;
       }
    }

    private formatDate(dateToBeFormatted: string): string {
        return moment(dateToBeFormatted).format("D MMMM YYYY");
    }

    private async downloadPDF(): Promise<void> {
        if (this.isPdfReady) {
            this.pdfDocument.contentDispositionFilename = this.document.fileName;
            this.pdfDocument.download();
        }
    }

    private async pdfReady(pdfApp: any): Promise<void> {
        this.isPdfReady = true;
        this.pdfDocument = pdfApp;
    }

    private async uploadTC(): Promise<void>{
        if(!this.isSelectedFTC && !this.isSelectedDraft){
            this.showErrorMsg = true;
            return
        }
        if(this.isSelectedFTC && this.tcRefNumber === ''){
            this.isTCrefEmpty = true;
            return
        }
        let finalSelectedOrders:UploadOrderModel[] = [];
        if(this.selectedType === 'UPLOADNEW'){
            finalSelectedOrders = this.selectedOrders.filter(s => s.isRequestSelected === true);
        }else{
            finalSelectedOrders = this.selectedOrders;
        }
        const user = this.$store.getters.user as UserModel;
		this.isDocUploading = true;
        const tranCertificateRefID = uuidv4()
        let tcType = TCType.DRAFT;
        if(this.isSelectedFTC){
            tcType = TCType.FINALTC;
        }
        if(finalSelectedOrders.length > 0){
            finalSelectedOrders.forEach(order => {
                const obj =  {
                    "ID": order.orderId,
                    "tranCertificate":order.certificate1or2,
                    "tcType": tcType,
                    "tcStatusValue": TCStatus.UPLOAD,
                    "tranCertificateRefID": tranCertificateRefID
                }
                this.orderList.push(obj)
            })
        }
        const orderIDs = {
            "orderList":this.orderList
        }
		const docFormData = new FormData();
		docFormData.append("tranCertificateRefID", tranCertificateRefID);
		docFormData.append("clientID",  finalSelectedOrders[0].clientId);
		docFormData.append("clientName", finalSelectedOrders[0].client);
		docFormData.append("cpSupplierID", user.companyId);
		docFormData.append("cpSupplierName", user.companyName);
		docFormData.append("tranCertificate", finalSelectedOrders[0].tranCertificate);
		docFormData.append("tcStatus",  TCStatus.UPLOAD);
        docFormData.append("tcType", tcType);
        docFormData.append("tcReferenceNumber", this.tcRefNumber);
        docFormData.append("upload", this.uploadCertificate.files);
        docFormData.append("orderIDs", JSON.stringify(orderIDs));
		const response = await this.certificateService.uploadTC(docFormData);
		if(response.success){
            this.isDocUploading = false;
            NotificationHelper.createSucceededNotification('The document has been uploaded successfully');
            const pendingOrders: OrderLineDisplayModel[] = this.$store.getters.pendingOrders;
            const storeOrders: OrderLineDisplayModel[] = this.$store.getters.orders;
            if(response.order.length > 0){
                response.order.forEach(o => {
                    const poIndex = pendingOrders.findIndex(po => po.orderId === o.orderId);
                    const orderIndex = storeOrders.findIndex(po => po.orderId === o.orderId);
                    const selectedIndex = finalSelectedOrders.findIndex(s => s.orderId === o.orderId);
                    if(poIndex!==-1 && selectedIndex!==-1){
                        pendingOrders.splice(poIndex, 1);
                        pendingOrders.splice(poIndex,0,o);
                        store.commit('setPendingOrders', pendingOrders);
                    }
                    if(orderIndex!==-1 && selectedIndex!==-1){
                        storeOrders.splice(orderIndex, 1);
                        storeOrders.splice(orderIndex,0,o);
                        store.commit('setOrders', storeOrders);
                    }
                });
            }
            const orderNumbers: String[] = [];
            this.selectedOrders.forEach(s => {
                orderNumbers.push(s.orderNumber);
            });
            const tcMailRequest: TCMailModel = {
                supplierCompanyName: user.companyName,
                tcDocType: tcType,
                tcType: this.selectedOrders[0].tranCertificate,
                orderNumbers: orderNumbers.join(', '),
                clientID: this.selectedOrders[0].clientId
            }
            this.supplierService.sendTCUploadMail(tcMailRequest);
            this.updateRequests();
            this.close();
		}
	}

    private toggleDraft(): void{
        this.isSelectedDraft = !this.isSelectedDraft;
        this.isSelectedFTC = false;
        this.showErrorMsg = false;
        this.isTCrefEmpty = false;
        this.tcRefNumber = '';
    }
    private toggleFinalTC(): void{
        this.isSelectedFTC = !this.isSelectedFTC;
        this.isSelectedDraft = false;
        this.showErrorMsg = false;
    }

    private resetError(): void{
        this.tcRefNumber = this.tcRefNumber.trim();
        if(this.tcRefNumber!==''){
            this.isTCrefEmpty = false;
        }
    }

    private closeModal(): void {
        if(!this.isDocUploading){
            this.close();
        }
    }

    private get isInActiveActions():boolean{
        if(this.documentDetails.tcData && this.selectedType === 'VIEWTC'){
            if(this.documentDetails.tcData.tcStatus === TCStatus.UPLOAD || this.documentDetails.tcData.tcStatus === TCStatus.REJECTED ||
                this.documentDetails.tcData.tcStatus === TCStatus.APPROVED || this.documentDetails.tcData.tcStatus === TCStatus.ARCHIVED
            ){
                return true;
            }
        }
        return false;
    }

    private get getTitle(): string {
        if(this.documentDetails.tcData!==undefined){
            if(this.documentDetails.tcData.tcStatus === TCStatus.APPROVED){
                if(this.documentDetails.tcData.tcType === "DRAFT"){
                     return 'Approved draft'
                }
                return 'Approved final TC'
            }
            if(this.documentDetails.tcData.tcStatus === TCStatus.UPLOAD){
                return 'Uploaded document'
            }
            if(this.documentDetails.tcData.tcStatus === TCStatus.REJECTED){
               return 'Rejected document'
            }
            if(this.documentDetails.tcData.tcStatus === TCStatus.ARCHIVED){
                return 'Archived document'
             }
        }
        return 'Upload document'
    }


    private get isAllUnselected(): boolean{
        if(this.selectedOrders.findIndex(f => f.isRequestSelected === true)===-1){
            return true;
        }
        return false;
    }

    @Emit()
    private close() {
    }

    @Emit()
    private updateRequests(){
    }
}
