import { EvidencePhotoListModel } from "./selectPictureModel";

export class OrderStepEvidenceModel {
  public ID!: string;
  public orderId!: string;
  public orderLineId?: string;
  public orderNo!: string;
  public styleNo!: string;
  public isSelected!: boolean;

  public constructor() {
    this.ID = '';
    this.orderId = '';
    this.orderLineId = '';
    this.orderNo = '';
    this.styleNo = '';
    this.isSelected = true;
  }
}

export class SubmitStepEvidenceModel {
  public orderLineStepID!: orderLineStepID;
  public referenceNo!: string;
  public evidencePhotoList!: EvidencePhotoListModel[];
}

export class orderLineStepID {
  public stepsList!: stepsList[];
}

export class stepsList {
  public ID!: string;
}
