import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';

// Services
import { EvidenceService } from '@/services/evidenceService';
import { OrderService } from '@/services/orderService';

// Components
import SelectOrder from '@/components/general/evidence/selectOrder/selectOrder.vue';

// Helper
import { GroupStepEvidenceHelper } from '@/helpers/groupStepEvidenceHelper';

// Models
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderStepEvidenceModel } from '@/models/orderStepEvidenceModel';
import { OrderLineStepEvidenceModel } from '@/models/orderLineStepEvidenceModel';
import { ApproveRejectModel } from '@/models/approveRejectModel';
import { orderLineStepIdModel } from '@/models/approveRejectModel';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { UserModel } from '@/models/userModel';
import { DropdownModel } from '@/models/dropdownModel';
import { UserPermissionModel } from '@/models/permissionModel';

@Component({
  directives: { ClickOutside },
  components: { SelectOrder },
  computed: mapGetters(['userRole', 'userPermissions']),
})
export default class ApproveRejectEvidence extends Vue {
  @Prop()
  private evidenceSteps!: OrderLineEvidenceStep[];

  private evidenceService: EvidenceService;

  private orderService: OrderService;

  private groupedSteps: GroupStepEvidenceModel[] = [];

  private OrderLineStepEvidenceModel!: OrderLineStepEvidenceModel;

  private selectedOrders: OrderStepEvidenceModel[] = [];

  private groupedStepOrders: GroupStepEvidenceModel =
    new GroupStepEvidenceModel();

  private type: string = 'groupSteps';

  private files: File[] = [];

  private uploading: boolean = false;

  private isLoading: boolean = false;

  private isSubmitting: boolean = false;

  private isRejSubmitting: boolean = false;

  private isApproved: boolean = false;

  private isRejected: boolean = false;

  private showSmallPopup: boolean = false;

  private disableSubmit: boolean = true;

  private disableRejectSubmit: boolean = false;

  private loadingStatus: boolean = false;

  private thankYouMessage: string = '';

  private currentPhoto: number = 0;

  private selectedRejectionReason: string = 'Incorrect picture';

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  public constructor() {
    super();
    this.evidenceService = new EvidenceService();
    this.orderService = new OrderService();
  }

  private async created(): Promise<void> {
    this.groupedSteps = GroupStepEvidenceHelper.groupStepEvidence(
      this.evidenceSteps
    );
    const auth = <HTMLDivElement>document.querySelector('.left-container');
    const sidebarMobile = <HTMLDivElement>(
      document.querySelector('.sidebar-mobile')
    );
    auth.style.setProperty('position', 'initial', 'important');
    sidebarMobile.style.setProperty('z-index', '9999', 'important');
  }

  private async beforeDestroy(): Promise<void> {
    const auth = <HTMLDivElement>document.querySelector('.left-container');
    const sidebarMobile = <HTMLDivElement>(
      document.querySelector('.sidebar-mobile')
    );
    auth.style.setProperty('position', 'fixed');
    sidebarMobile.style.setProperty('z-index', 'unset', 'important');
  }

  private navSelectOrders(index: number): void {
    if (this.approveOrderEvidencePermission === 1) {
      this.groupedSteps[index].nextStepCompany = this.$t(
        'pages.order_evidence.me'
      ).toString();
      const steps = this.groupedSteps[index];
      this.groupedStepOrders = steps;
      this.selectedOrders = this.groupedStepOrders.orderStyle;
      this.type = 'selectOrder';
    }
  }

  private selectedOrderList(selectedOrders: OrderStepEvidenceModel[]): void {
    this.selectedOrders = selectedOrders;
  }

  private get rejectionReasons(): DropdownModel[] {
    const options: DropdownModel[] = [
      {
        value: 'Incorrect picture',
        text: this.$t('pages.order_evidence.incorrect_picture').toString(),
      },
      {
        value: 'Not readable picture',
        text: this.$t('pages.order_evidence.not_readable').toString(),
      },
    ];
    return options;
  }

  private get approveOrderEvidencePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.approveOrderEvidence
      : 0;
  }

  private async approveRejectMain(): Promise<void> {
    this.isLoading = true;
    const result = await this.evidenceService.getEvidencePhotos(
      this.selectedOrders[0].orderId,
      this.selectedOrders[0].orderLineId,
      this.selectedOrders[0].ID
    );
    this.OrderLineStepEvidenceModel = result.files;
    this.type = 'selectedEvidence';
    this.isApproved = false;
    this.isRejected = false;
    this.disableSubmit = true;
    this.isLoading = false;
  }

  private async approveSubmit(statusNotSelected: boolean): Promise<void> {
    if (statusNotSelected) {
      return;
    }

    if (this.isRejected) {
      const auth = <HTMLDivElement>document.querySelector('.left-container');
      auth.style.setProperty('position', 'fixed');
      auth.style.setProperty('z-index', 'unset', 'important');
      this.showSmallPopup = true;
      return;
    }
    this.isSubmitting = true;
    this.disableSubmit = true;
    const data: ApproveRejectModel = {
      orderLineStepID: new orderLineStepIdModel(),
      rejectionReason: '',
      status: 0,
    };
    data.rejectionReason = '';
    data.status = OrderLineStepStatus.Accepted;
    let counter = 0;
    this.selectedOrders.forEach((o) => {
      if (counter == 0) {
        data.orderLineStepID.stepsList.pop();
      }
      const stepId = { ID: o.ID };
      data.orderLineStepID.stepsList.push(stepId);
      counter++;
    });
    const user = this.$store.getters.user as UserModel;
    const result = await this.evidenceService.postEvidenceStatus(
      user.companyId,
      data
    );
    this.isSubmitting = false;
    this.type = 'thankYouPage';
    if (this.isApproved) {
      this.thankYouMessage = this.$t(
        'pages.order_evidence.selected_evidence_msg'
      ).toString();
    } else {
      this.thankYouMessage = this.$t(
        'pages.order_evidence.selected_evidence_rejected_msg'
      ).toString();
    }
  }

  private async rejectSubmit(statusNotSelected: boolean): Promise<void> {
    if (statusNotSelected) {
      return;
    }
    this.isRejSubmitting = true;
    this.disableRejectSubmit = true;
    const data: ApproveRejectModel = {
      orderLineStepID: new orderLineStepIdModel(),
      rejectionReason: '',
      status: 0,
    };
    data.rejectionReason = this.selectedRejectionReason;
    data.status = OrderLineStepStatus.Rejected;
    let counter = 0;
    this.selectedOrders.forEach((o) => {
      if (counter == 0) {
        data.orderLineStepID.stepsList.pop();
      }
      const stepId = { ID: o.ID };
      data.orderLineStepID.stepsList.push(stepId);
      counter++;
    });
    const user = this.$store.getters.user as UserModel;
    const result = await this.evidenceService.postEvidenceStatus(
      user.companyId,
      data
    );
    this.isRejSubmitting = false;
    const auth = <HTMLDivElement>document.querySelector('.left-container');
    auth.style.setProperty('position', 'initial');
    auth.style.setProperty('z-index', '9', 'important');
    this.type = 'thankYouPage';
    if (this.isApproved) {
      this.thankYouMessage = this.$t(
        'pages.order_evidence.selected_evidence_msg'
      ).toString();
    } else {
      this.thankYouMessage = this.$t(
        'pages.order_evidence.selected_evidence_rejected_msg'
      ).toString();
    }
  }

  private async approveRejectAllPhotos(
    status: string,
    isApprovedOrRejected: boolean
  ): Promise<void> {
    if (status == 'approved' && isApprovedOrRejected) {
      return;
    } else if (status == 'rejected' && isApprovedOrRejected) {
      return;
    }
    this.disableSubmit = false;
    this.isApproved = status == 'approved' ? true : false;
    this.isRejected = status == 'rejected' ? true : false;
  }

  private vueClickedOutside(): void {
    this.showSmallPopup = false;
    const auth = <HTMLDivElement>document.querySelector('.left-container');
    auth.style.setProperty('position', 'initial');
    auth.style.setProperty('z-index', '9', 'important');
  }

  private closeSmallPopup(): void {
    this.showSmallPopup = false;
    const auth = <HTMLDivElement>document.querySelector('.left-container');
    auth.style.setProperty('position', 'initial');
    auth.style.setProperty('z-index', '9', 'important');
  }

  private closeOrder(): void {
    this.type = 'groupSteps';
  }

  private closeEvidence(): void {
    this.type = 'selectOrder';
  }

  private closeApproveReject(): void {
    this.closeUpload();
    this.reloadEvidence();
  }

  @Emit()
  private closeUpload(): void {}

  @Emit()
  private reloadEvidence(): void {}
}
