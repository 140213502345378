//Sprint-24 TTD-116
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import moment from "moment";
import { mapState } from 'vuex';
import { cloneDeep, uniqBy, uniq } from "lodash";
import lodash from "lodash";
import i18n from "@/i18n";
import { DropdownModel } from "@/models/dropdownModel";
import { ActionTypes } from "@/store/actions/actions";

// Services
import { ComplianceService } from "@/services/complianceService";

// Modals
import { UserModel } from "@/models/userModel";
import { UserPermissionModel } from "@/models/permissionModel";
import { TCModel, UploadOrderModel, TCDocumentModel } from "@/models/transcationCertificateModal";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { Guid } from "@/models/guid";
import { TCtableModel, TCStatus, TCType } from "../../../models/tcTableModel";

//components
import ViewTcCertificates from "@/views/compliance/components/viewTcCertificates.vue";

// Helper
import { NotificationHelper } from '@/helpers/notificationHelper';


@Component({
    components: { ViewTcCertificates },
	computed: mapState(['userPermissions','userRole', 'pendingOrders', 'orders', 'isOrderLoading'])
})

export default class SentComplianceDocuments extends Vue {

	@Prop()
	private isLoading!: boolean;

	@Prop()
	private selectedTab!: number;

	@Prop()
  private searchFilter!: string;

	@Prop()
  private selectedBrand!: string;

  @Prop()
	private selectedType!: string;

  @Prop()
	private selectedClient!: string;

  private isOrderLoading!: boolean;

  private orders!: OrderLineDisplayModel[];

  private pendingOrders!: OrderLineDisplayModel[];

  private complianceService: ComplianceService;

  private showPDFModal: boolean = false;

  private userPermissions!: UserPermissionModel | null;

  private showDeleteDocModal: boolean = false;

  private user: UserModel = new UserModel;

  private userRole!: string;

  private isOpenTCSection: boolean = false;

  private isOpen: boolean = false;

  private selectedSortOption: string = "";

  private isSortAtoZ: boolean = false;

  private isSortZtoA: boolean = false;

  private isDeleting: boolean = false;

  private pendingReuests: OrderLineDisplayModel[] = [];

  private uploaded: TCtableModel[] = [];

  private finalTC: TCtableModel[] = [];

  private archive: TCtableModel[] = [];

  private sortType: string = 'asc';

  private pendingTc: TCtableModel[] = [];

  private initialPendingTC: TCtableModel[] = [];

  private isShowSendRemainder: boolean = false;

  private selectedOrderIDs: UploadOrderModel[] = [];

  private showReminderPopup: boolean = false;

  private documentName: string = '';

  private selectedPendingRequestIndex: number = -1;

  private showUploadDocModal: boolean = false;

  private isDocUploading: boolean = false;

  private pdfDocument: any = {};

  private showWarnging: boolean = false;

  private showBulkBar: boolean = false;

  private selectedPending: UploadOrderModel[] = [];

  private selectedRejected: UploadOrderModel[] = [];

  private selectedApprovedDrafts: UploadOrderModel[] = [];

  private isDocLoading: boolean = false;

  private uploadCertificate: any = {
      files: [],
      description: '',
      geoLat: 0,
      geoLong: 0,
      type: 0,
    };

  private isUploadError: any = {
      files: -1,
      description: -1,
      geoLat: -1,
      geoLong: -1,
  };

  private currentView: string = '';

  private selectedCertificate!: TCtableModel;

  private showCerts: boolean = false;

  private certificateType: string = '';

  private initialUploaded: TCtableModel[] = [];

  private initialFinalTC: TCtableModel[] = [];

  private initialArchive: TCtableModel[] = [];

  private rejected: TCtableModel[] = [];

  private initialRejected: TCtableModel[] = [];

  private approvedDraft: TCtableModel[] = [];

  private initialApprovedDraft: TCtableModel[] = [];

  private selectedTCType: string = '';

  private documentDetails: TCDocumentModel = new TCDocumentModel();

  private rejectedReason: string = '';

  private showRejectionDoc: boolean = false;

  private tcSections: TCModel[] = [
    {tcSection: "Pending TC request(s)", isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
    {tcSection: "Uploaded document(s)", isSelectedAll: false, isCollapse: false, isOpen: false,  isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
    {tcSection: "Rejected document(s)", isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
    {tcSection: "Approved draft(s)", isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
    {tcSection: "Final TC approved", isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""},
    {tcSection: "Archive", isSelectedAll: false, isCollapse: false, isOpen:false, isSortAtoZ: false, isSortZtoA: false, sortType: 'asc', selectedSortOption: ""}
  ]

	public constructor() {
		super();
		this.complianceService = new ComplianceService();
	}

	private async created():Promise<void> {
        this.user = this.$store.getters.user as UserModel;
        if (this.isOrderLoading === false && this.orders.length === 0) {
            await this.$store.dispatch(ActionTypes.SET_ORDERLINE);
          }
        this.getFilteredOrders();
	}

    private getFilteredOrders():void {
        this.pendingTc = [];
        this.initialPendingTC = [];
        this.uploaded = [];
        this.initialUploaded = [];
        this.rejected = [];
        this.initialRejected = [];
        this.finalTC = [];
        this.initialFinalTC = [];
        this.approvedDraft = [];
        this.initialApprovedDraft = [];
        this.archive = [];
        this.initialArchive = [];
        const pendingOrders: OrderLineDisplayModel[] = this.$store.getters.pendingOrders;
        const orders: OrderLineDisplayModel[] = this.$store.getters.orders;
        this.pendingTc = [];
        if(pendingOrders.length > 0 || orders.length > 0){
            const pending = pendingOrders.filter(order => (order.tc1Status === TCStatus.PENDING || order.tc2Status === TCStatus.PENDING) && order.toCompanyId!==Guid.Empty);
            const order = orders.filter(order => (order.tc1Status === TCStatus.PENDING || order.tc2Status === TCStatus.PENDING) && order.toCompanyId!==Guid.Empty);
            const allOrders: OrderLineDisplayModel[] = lodash.uniqBy([...pending, ...order], 'orderId');
            if(allOrders.length > 0){
                allOrders.forEach(pr => {
                    const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                    if(pr.tranCertificate1!=='' && pr.levelTC1!=='' && pr.tc1Status === TCStatus.PENDING){
                      this.pendingTc.push(new TCtableModel([pr.orderId], pr.tranCertificate1, '', pr.levelTC1, '', pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, '',pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                    }
                    if(pr.tranCertificate2!=='' && pr.levelTC2!=='' && pr.tc2Status=== TCStatus.PENDING){
                      this.pendingTc.push(new TCtableModel([pr.orderId],'', pr.tranCertificate2, '', pr.levelTC2, pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type,pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                    }
                });
                this.initialPendingTC = cloneDeep(this.pendingTc);
                this.pendingTc.forEach(pt => pt.isSelectedTC = false);
            }
            if(this.pendingTc.length > 0){
              this.tcSections[0].isCollapse = true;
            }else{
              this.tcSections[0].isCollapse = false;
            }

            const uploadedA = pendingOrders.filter(order => (order.tc1Status === TCStatus.UPLOAD || order.tc2Status === TCStatus.UPLOAD ) && order.toCompanyId!==Guid.Empty);
            const uploadedB = orders.filter(order => (order.tc1Status === TCStatus.UPLOAD || order.tc2Status === TCStatus.UPLOAD ) && order.toCompanyId!==Guid.Empty);
            const filteredUploaded: OrderLineDisplayModel[] = lodash.uniqBy([...uploadedA, ...uploadedB], 'orderId');
           if(filteredUploaded.length > 0){
               filteredUploaded.forEach(pr => {
                   const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                   if(pr.tranCertificate1 !== '' && pr.levelTC1 !== '' && pr.tc1Status!==TCStatus.APPROVED && pr.tc1Status!==TCStatus.REJECTED && pr.tc1Status!==TCStatus.ARCHIVED &&
                      (pr.tc1Type === TCType.DRAFT || pr.tc1Type === TCType.FINALTC)){
                       const tc1Index = this.uploaded.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                       if(tc1Index === -1){
                           this.uploaded.push(new TCtableModel([pr.orderId],pr.tranCertificate1, '', pr.levelTC1, '', pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, '',pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                       } else {
                           this.uploaded[tc1Index].orderIds.push(pr.orderId);
                           this.uploaded[tc1Index].brandNames.push(pr.brandName);
                           this.uploaded[tc1Index].orderNumbers.push(pr.orderNumber);
                           this.uploaded[tc1Index].styleNumbers.push(pr.styleNumber);
                           this.uploaded[tc1Index].styleNames.push(pr.styleName);
                           this.uploaded[tc1Index].colours.push(colour);
                           this.uploaded[tc1Index].linkedOrderIDs.push(pr.orderId);
                       }
                   }
                   if(pr.tranCertificate2 !== '' && pr.levelTC2 !== '' && pr.tc2Status!==TCStatus.APPROVED && pr.tc2Status!==TCStatus.REJECTED && pr.tc2Status!==TCStatus.ARCHIVED &&
                     (pr.tc2Type === TCType.DRAFT || pr.tc2Type === TCType.FINALTC)){
                       const tc2Index = this.uploaded.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                       if(tc2Index === -1){
                           this.uploaded.push(new TCtableModel([pr.orderId],'', pr.tranCertificate2, '', pr.levelTC2, pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type,pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                       } else {
                           this.uploaded[tc2Index].orderIds.push(pr.orderId);
                           this.uploaded[tc2Index].brandNames.push(pr.brandName);
                           this.uploaded[tc2Index].orderNumbers.push(pr.orderNumber);
                           this.uploaded[tc2Index].styleNumbers.push(pr.styleNumber);
                           this.uploaded[tc2Index].styleNames.push(pr.styleName);
                           this.uploaded[tc2Index].colours.push(colour);
                           this.uploaded[tc2Index].linkedOrderIDs.push(pr.orderId);
                       }
                   }
               })
               const sortedUploaded: TCtableModel[] = [];
               const sortedUploadDrafts: TCtableModel[] = [];
               sortedUploadDrafts.push(...this.uploaded.filter(d => d.tc1Type === TCType.DRAFT || d.tc2Type === TCType.DRAFT));
               sortedUploadDrafts.sort((a: TCtableModel, b: TCtableModel) => {
                   if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                       return -1;
                   } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                       return 1;
                   }
                   return 0;
               });
               const sortedUploadFTC: TCtableModel[] = [];
               sortedUploadFTC.push(...this.uploaded.filter(d => d.tc1Type === TCType.FINALTC || d.tc2Type === TCType.FINALTC));
               sortedUploadFTC.sort((a: TCtableModel, b: TCtableModel) => {
                   if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                       return -1;
                   } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                       return 1;
                   }
                   return 0;
               });
               sortedUploaded.push(...sortedUploadDrafts, ...sortedUploadFTC);
               this.uploaded = cloneDeep(sortedUploaded);
               this.initialUploaded = cloneDeep(this.uploaded);
               if(this.uploaded.length > 0){
                this.tcSections[1].isCollapse = true;
               }
           }else{
               this.tcSections[1].isCollapse = false;
           }

           const rejectedA = pendingOrders.filter(order => (order.tc1Status === TCStatus.REJECTED || order.tc2Status === TCStatus.REJECTED) && order.toCompanyId!==Guid.Empty);
           const rejectedB = orders.filter(order => (order.tc1Status === TCStatus.REJECTED || order.tc2Status === TCStatus.REJECTED) && order.toCompanyId!==Guid.Empty);
           const filteredRejected: OrderLineDisplayModel[] = lodash.uniqBy([...rejectedA, ...rejectedB], 'orderId');
          if(filteredRejected.length > 0){
              filteredRejected.forEach(pr => {
                  const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                  if(pr.tranCertificate1 !== '' && pr.levelTC1 !== '' && pr.tc1Status!==TCStatus.PENDING && pr.tc1Status!==TCStatus.APPROVED && pr.tc1Status!==TCStatus.UPLOAD && pr.tc1Status!==TCStatus.ARCHIVED && (pr.tc1Type === TCType.DRAFT || pr.tc1Type === TCType.FINALTC)){
                      const tc1Index = this.rejected.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                      if(tc1Index === -1){
                          this.rejected.push(new TCtableModel([pr.orderId],pr.tranCertificate1, '', pr.levelTC1, '', pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, '',pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                      } else {
                          this.rejected[tc1Index].orderIds.push(pr.orderId);
                          this.rejected[tc1Index].brandNames.push(pr.brandName);
                          this.rejected[tc1Index].orderNumbers.push(pr.orderNumber);
                          this.rejected[tc1Index].styleNumbers.push(pr.styleNumber);
                          this.rejected[tc1Index].styleNames.push(pr.styleName);
                          this.rejected[tc1Index].colours.push(colour);
                          this.rejected[tc1Index].linkedOrderIDs.push(pr.orderId);
                      }
                  }
                  if(pr.tranCertificate2 !== '' && pr.levelTC2 !== '' && pr.tc2Status!==TCStatus.PENDING && pr.tc2Status!==TCStatus.APPROVED && pr.tc2Status!==TCStatus.UPLOAD && pr.tc2Status!==TCStatus.ARCHIVED && (pr.tc2Type === TCType.DRAFT || pr.tc2Type === TCType.FINALTC)){
                      const tc2Index = this.rejected.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                      if(tc2Index === -1){
                          this.rejected.push(new TCtableModel([pr.orderId],'', pr.tranCertificate2, '', pr.levelTC2, pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type,pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                      } else {
                          this.rejected[tc2Index].orderIds.push(pr.orderId);
                          this.rejected[tc2Index].brandNames.push(pr.brandName);
                          this.rejected[tc2Index].orderNumbers.push(pr.orderNumber);
                          this.rejected[tc2Index].styleNumbers.push(pr.styleNumber);
                          this.rejected[tc2Index].styleNames.push(pr.styleName);
                          this.rejected[tc2Index].colours.push(colour);
                          this.rejected[tc2Index].linkedOrderIDs.push(pr.orderId);
                      }
                  }
              })
              const sortedUploaded: TCtableModel[] = [];
              const sortedUploadDrafts: TCtableModel[] = [];
              sortedUploadDrafts.push(...this.rejected.filter(d => d.tc1Type === TCType.DRAFT || d.tc2Type === TCType.DRAFT));
              sortedUploadDrafts.sort((a: TCtableModel, b: TCtableModel) => {
                  if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                      return -1;
                  } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                      return 1;
                  }
                  return 0;
              });
              const sortedUploadFTC: TCtableModel[] = [];
              sortedUploadFTC.push(...this.rejected.filter(d => d.tc1Type === TCType.FINALTC || d.tc2Type === TCType.FINALTC));
              sortedUploadFTC.sort((a: TCtableModel, b: TCtableModel) => {
                  if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                      return -1;
                  } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                      return 1;
                  }
                  return 0;
              });
              sortedUploaded.push(...sortedUploadDrafts, ...sortedUploadFTC);
              this.rejected = cloneDeep(sortedUploaded);
              this.initialRejected = cloneDeep(this.rejected);
              if(this.rejected.length > 0){
                this.tcSections[2].isCollapse = true;
              }
          }else{
              this.tcSections[2].isCollapse = false;
          }


          const approvedDraftA = pendingOrders.filter(order => (order.tc1Status === TCStatus.APPROVED || order.tc2Status === TCStatus.APPROVED) && (order.tc1Type === TCType.DRAFT || order.tc2Type === TCType.DRAFT) && (order.tc1Type !== TCType.FINALTC || order.tc2Type !== TCType.FINALTC) && order.toCompanyId!==Guid.Empty);
          const approvedDraftB = orders.filter(order => (order.tc1Status === TCStatus.APPROVED || order.tc2Status === TCStatus.APPROVED) && (order.tc1Type === TCType.DRAFT || order.tc2Type === TCType.DRAFT) && (order.tc1Type !== TCType.FINALTC || order.tc2Type !== TCType.FINALTC) && order.toCompanyId!==Guid.Empty);
          const filteredApprovedDrafts: OrderLineDisplayModel[] = lodash.uniqBy([...approvedDraftA, ...approvedDraftB], 'orderId');
         if(filteredApprovedDrafts.length > 0){
             filteredApprovedDrafts.forEach(pr => {
                 const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                 if(pr.tranCertificate1 !== '' && pr.levelTC1 !== '' && pr.tc1Status!==TCStatus.PENDING && pr.tc1Status!==TCStatus.UPLOAD && pr.tc1Status!==TCStatus.REJECTED && pr.tc1Status!==TCStatus.ARCHIVED && pr.tc1Type!==TCType.FINALTC){
                     const tc1Index = this.approvedDraft.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                     if(tc1Index === -1){
                         this.approvedDraft.push(new TCtableModel([pr.orderId],pr.tranCertificate1, '', pr.levelTC1, '', pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, '',pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                     } else {
                         this.approvedDraft[tc1Index].orderIds.push(pr.orderId);
                         this.approvedDraft[tc1Index].brandNames.push(pr.brandName);
                         this.approvedDraft[tc1Index].orderNumbers.push(pr.orderNumber);
                         this.approvedDraft[tc1Index].styleNumbers.push(pr.styleNumber);
                         this.approvedDraft[tc1Index].styleNames.push(pr.styleName);
                         this.approvedDraft[tc1Index].colours.push(colour);
                         this.approvedDraft[tc1Index].linkedOrderIDs.push(pr.orderId);
                     }
                 }
                 if(pr.tranCertificate2 !== '' && pr.levelTC2 !== '' && pr.tc2Status!==TCStatus.UPLOAD && pr.tc2Status!==TCStatus.REJECTED && pr.tc2Status!==TCStatus.ARCHIVED && pr.tc2Status!==TCStatus.PENDING && pr.tc2Type!==TCType.FINALTC){
                     const tc2Index = this.approvedDraft.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                     if(tc2Index === -1){
                         this.approvedDraft.push(new TCtableModel([pr.orderId],'', pr.tranCertificate2, '', pr.levelTC2, pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type,pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                     } else {
                         this.approvedDraft[tc2Index].orderIds.push(pr.orderId);
                         this.approvedDraft[tc2Index].brandNames.push(pr.brandName);
                         this.approvedDraft[tc2Index].orderNumbers.push(pr.orderNumber);
                         this.approvedDraft[tc2Index].styleNumbers.push(pr.styleNumber);
                         this.approvedDraft[tc2Index].styleNames.push(pr.styleName);
                         this.approvedDraft[tc2Index].colours.push(colour);
                         this.approvedDraft[tc2Index].linkedOrderIDs.push(pr.orderId);
                     }
                 }
             })
             const sortedUploaded: TCtableModel[] = [];
             const sortedUploadDrafts: TCtableModel[] = [];
             sortedUploadDrafts.push(...this.approvedDraft.filter(d => d.tc1Type === TCType.DRAFT || d.tc2Type === TCType.DRAFT));
             sortedUploadDrafts.sort((a: TCtableModel, b: TCtableModel) => {
                 if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                     return -1;
                 } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                     return 1;
                 }
                 return 0;
             });
             const sortedUploadFTC: TCtableModel[] = [];
             sortedUploadFTC.push(...this.approvedDraft.filter(d => d.tc1Type === TCType.FINALTC || d.tc2Type === TCType.FINALTC));
             sortedUploadFTC.sort((a: TCtableModel, b: TCtableModel) => {
                 if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                     return -1;
                 } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                     return 1;
                 }
                 return 0;
             });
             sortedUploaded.push(...sortedUploadDrafts, ...sortedUploadFTC);
             this.approvedDraft = cloneDeep(sortedUploaded);
             this.initialApprovedDraft = cloneDeep(this.approvedDraft);
             if(this.approvedDraft.length > 0){
              this.tcSections[3].isCollapse = true;
             }
         }else{
             this.tcSections[3].isCollapse = false;
         }


           const finalTCA = pendingOrders.filter(order => (order.tc1Status === TCStatus.APPROVED || order.tc2Status === TCStatus.APPROVED) && (order.tc1Type === TCType.FINALTC || order.tc2Type === TCType.FINALTC) && order.toCompanyId!==Guid.Empty);
           const finalTCB = orders.filter(order => (order.tc1Status === TCStatus.APPROVED || order.tc2Status === TCStatus.APPROVED) && (order.tc1Type === TCType.FINALTC || order.tc2Type === TCType.FINALTC) && order.toCompanyId!==Guid.Empty);
           const filteredFinalTC: OrderLineDisplayModel[] = lodash.uniqBy([...finalTCA, ...finalTCB], 'orderId');
           if(filteredFinalTC.length > 0){
               filteredFinalTC.forEach(pr => {
                   const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                   if(pr.tranCertificate1 !== '' && pr.levelTC1 !== '' && pr.tc1Status !== '' && pr.tc1Status !== TCStatus.UPLOAD && pr.tc1Status !== TCStatus.ARCHIVED && pr.tc1Status!== TCStatus.REJECTED && pr.tc1Type === TCType.FINALTC){
                       const tc1Index = this.finalTC.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                       if(tc1Index === -1){
                           this.finalTC.push(new TCtableModel([pr.orderId],pr.tranCertificate1, '', pr.levelTC1, '', pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, '',pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                       } else {
                           this.finalTC[tc1Index].orderIds.push(pr.orderId);
                           this.finalTC[tc1Index].brandNames.push(pr.brandName);
                           this.finalTC[tc1Index].orderNumbers.push(pr.orderNumber);
                           this.finalTC[tc1Index].styleNumbers.push(pr.styleNumber);
                           this.finalTC[tc1Index].styleNames.push(pr.styleName);
                           this.finalTC[tc1Index].colours.push(colour);
                           this.finalTC[tc1Index].linkedOrderIDs.push(pr.orderId);
                       }
                   }
                   if(pr.tranCertificate2!=='' && pr.levelTC2!=='' && pr.tc2Status !== TCStatus.UPLOAD && pr.tc2Status !== TCStatus.ARCHIVED && pr.tc2Status!== TCStatus.REJECTED &&  pr.tc2Status !== '' && pr.tc2Type === TCType.FINALTC){
                       const tc2Index = this.finalTC.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                       if(tc2Index === -1){
                           this.finalTC.push(new TCtableModel([pr.orderId],'', pr.tranCertificate2, '', pr.levelTC2, pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type,pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                       } else {
                           this.finalTC[tc2Index].orderIds.push(pr.orderId);
                           this.finalTC[tc2Index].brandNames.push(pr.brandName);
                           this.finalTC[tc2Index].orderNumbers.push(pr.orderNumber);
                           this.finalTC[tc2Index].styleNumbers.push(pr.styleNumber);
                           this.finalTC[tc2Index].styleNames.push(pr.styleName);
                           this.finalTC[tc2Index].colours.push(colour);
                           this.finalTC[tc2Index].linkedOrderIDs.push(pr.orderId);
                       }
                   }
               });
               const sortedApprovedFinalTcs: TCtableModel[] = [];
               sortedApprovedFinalTcs.push(...this.finalTC.filter(d => d.tc1Status === TCStatus.APPROVED || d.tc2Status === TCStatus.APPROVED));
               sortedApprovedFinalTcs.sort((a: TCtableModel, b: TCtableModel) => {
                   if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
                       return -1;
                   } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
                       return 1;
                   }
                   return 0;
               });
               const sortedFinalTcs: TCtableModel[] = [];
               sortedFinalTcs.push(...sortedApprovedFinalTcs);
               this.finalTC = sortedFinalTcs;
               this.initialFinalTC = cloneDeep(this.finalTC);
               if(this.finalTC.length > 0){
                this.tcSections[4].isCollapse = true;
               }
           }else{
               this.tcSections[4].isCollapse = false;
           }

           const archiveA = pendingOrders.filter(order => (order.tc1Status === TCStatus.ARCHIVED || order.tc2Status === TCStatus.ARCHIVED) && order.toCompanyId!==Guid.Empty);
           const archiveB = orders.filter(order => (order.tc1Status === TCStatus.ARCHIVED || order.tc2Status === TCStatus.ARCHIVED) && order.toCompanyId!==Guid.Empty);
           const filteredArchive: OrderLineDisplayModel[] = lodash.uniqBy([...archiveA, ...archiveB], 'orderId');
           if(filteredArchive.length > 0){
               filteredArchive.forEach(pr => {
                   const colour = pr.orderLines[0].colourway ? pr.orderLines[0].colourway : ''
                   if(pr.tranCertificate1!=='' && pr.levelTC1!=='' && pr.tc1Status === TCStatus.ARCHIVED){
                       const tc1Index = this.archive.findIndex(d => d.tranCertificate1RefId === pr.tranCertificate1RefId);
                       if(tc1Index === -1){
                           this.archive.push(new TCtableModel([pr.orderId],pr.tranCertificate1, '', pr.levelTC1, '', pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, pr.tranCertificate1RefId, '', pr.tc1Status, '', pr.tcReferenceNumber, '', pr.tc1Type, '',pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                       } else {
                           this.archive[tc1Index].orderIds.push(pr.orderId);
                           this.archive[tc1Index].brandNames.push(pr.brandName);
                           this.archive[tc1Index].orderNumbers.push(pr.orderNumber);
                           this.archive[tc1Index].styleNumbers.push(pr.styleNumber);
                           this.archive[tc1Index].styleNames.push(pr.styleName);
                           this.archive[tc1Index].colours.push(colour);
                           this.archive[tc1Index].linkedOrderIDs.push(pr.orderId);
                       }
                   }
                   if(pr.tranCertificate2!=='' && pr.levelTC2!=='' && pr.tc2Status === TCStatus.ARCHIVED){
                       const tc2Index = this.archive.findIndex(d => d.tranCertificate2RefId === pr.tranCertificate2RefId);
                       if(tc2Index === -1){
                           this.archive.push(new TCtableModel([pr.orderId],'', pr.tranCertificate2, '', pr.levelTC2, pr.toCompanyName, pr.expectedTimeOfDelivery, [pr.brandName], [pr.orderNumber], [pr.styleNumber], [pr.styleName], [colour], [pr.orderId], pr.toCompanyId, '', pr.tranCertificate2RefId, '', pr.tc2Status, '', pr.tcReferenceNumber1, '', pr.tc2Type,pr.fromCompanyName, pr.fromCompanyUser.ID,''));
                       } else {
                           this.archive[tc2Index].orderIds.push(pr.orderId);
                           this.archive[tc2Index].brandNames.push(pr.brandName);
                           this.archive[tc2Index].orderNumbers.push(pr.orderNumber);
                           this.archive[tc2Index].styleNumbers.push(pr.styleNumber);
                           this.archive[tc2Index].styleNames.push(pr.styleName);
                           this.archive[tc2Index].colours.push(colour);
                           this.archive[tc2Index].linkedOrderIDs.push(pr.orderId);
                       }
                   }
               })
               this.initialArchive = cloneDeep(this.archive);
               // this.archive.forEach(pt => pt.isSelectedTC = false);
           }
        }
    }

    private formattedDate(date: string): string {
		return moment(date).format("D MMM YYYY");
	}

  private getBrands(brands: string[]): string {
    return uniq(brands).join(', ');
  }

  private getOrderNumbers(orderNumbers: string[]): string {
      return uniq(orderNumbers).join(', ');
  }

  private getStyleNumbers(styleNumbers: string[]): string {
      return uniq(styleNumbers).join(', ');
  }

  private getStyleNames(styleNames: string[]): string {
      return uniq(styleNames).join(', ');
  }

  private getColors(colours: string[]): string {
      return uniq(colours).join(', ');
  }


    private selectAll(index: number): void{
        this.selectedOrderIDs = [];
        this.selectedPending = [];
        this.selectedRejected = [];
        this.selectedApprovedDrafts = [];
        if(index===0){
            this.deSelectAll(2);
            this.deSelectAll(3);
            this.tcSections[index].isSelectedAll = true;
            this.pendingTc.forEach(certificate => {
              certificate.isSelectedTC = true;
              this.pushTCObj(certificate, index);
            });
        }else if(index === 2){
            this.deSelectAll(0);
            this.deSelectAll(3);
            this.tcSections[index].isSelectedAll = true;
            this.rejected.forEach(certificate => {
              certificate.isSelectedTC = true;
              this.pushTCObj(certificate, index);
            });
        }else{
          this.deSelectAll(0);
          this.deSelectAll(2);
          this.tcSections[index].isSelectedAll = true;
          this.approvedDraft.forEach(certificate => {
            certificate.isSelectedTC = true;
            this.pushTCObj(certificate, index);
          });
        }
        if(this.selectedOrderIDs.length > 1){
          this.showBulkBar = true;
        }
    }

    private deSelectAll(index: number): void{
        if(index===0){
            this.tcSections[index].isSelectedAll = false;
            this.pendingTc.forEach(pt => pt.isSelectedTC = false);
            this.selectedPending = [];
        }else if(index === 2){
            this.tcSections[index].isSelectedAll = false;
            this.rejected.forEach(pt => pt.isSelectedTC = false);
            this.selectedRejected = [];
        }else {
            this.tcSections[index].isSelectedAll = false;
            this.approvedDraft.forEach(pt => pt.isSelectedTC = false);
            this.selectedApprovedDrafts = [];
        }
        this.selectedOrderIDs = [];
        this.showBulkBar = false;
    }

    private toggleSelectTc(sectionIndex:number, tc:TCtableModel, tcIndex:number): void{
        this.selectedOrderIDs = [];
        tc.isSelectedTC=!tc.isSelectedTC;
        if(sectionIndex === 0){
            const certificate= cloneDeep(tc);
            if(this.selectedRejected.length > 0 && certificate.isSelectedTC){
              this.deSelectAll(2);
            }
            if(this.selectedApprovedDrafts.length > 0 && certificate.isSelectedTC){
              this.deSelectAll(3);
            }
            this.pendingTc.splice(tcIndex, 1);
            this.pendingTc.splice(tcIndex, 0, certificate);
            if(this.pendingTc.every(pt => pt.isSelectedTC === true)){
                this.tcSections[sectionIndex].isSelectedAll = true;
            }else{
                this.tcSections[sectionIndex].isSelectedAll = false;
            }
            if(certificate.isSelectedTC){
              this.pushTCObj(certificate, sectionIndex);
            }else{
                if(this.selectedPending.length > 0){
                    const orderIdIndex = this.selectedPending.findIndex(s => s.orderId === certificate.orderIds[0]);
                    if(orderIdIndex!==-1){
                        this.selectedPending.splice(orderIdIndex,1);
                    }
                }
                this.selectedOrderIDs = this.selectedPending;
            }
        }else if(sectionIndex === 2){
          const certificate= cloneDeep(tc);
          if(this.selectedPending.length > 0 && certificate.isSelectedTC){
            this.deSelectAll(0);
          }
          if(this.selectedApprovedDrafts.length > 0 && certificate.isSelectedTC){
            this.deSelectAll(3);
          }
          this.rejected.splice(tcIndex, 1);
          this.rejected.splice(tcIndex, 0, certificate);
          if(this.rejected.every(pt => pt.isSelectedTC === true)){
              this.tcSections[sectionIndex].isSelectedAll = true;
          }else{
              this.tcSections[sectionIndex].isSelectedAll = false;
          }
          if(certificate.isSelectedTC){
            this.pushTCObj(certificate, sectionIndex);
          }else{
              if(this.selectedRejected.length > 0){
                  const ref = certificate.tranCertificate1RefId!== '' ? certificate.tranCertificate1RefId : certificate.tranCertificate2RefId;
                  for(let i= this.selectedRejected.length -1; i >=0; i--){
                    if(this.selectedRejected[i].tranCertificateRefId === ref){
                      this.selectedRejected.splice(i,1);
                    }
                  }
              }
              this.selectedOrderIDs = this.selectedRejected;
          }
        }else{
          const certificate= cloneDeep(tc);
          if(this.selectedPending.length > 0 && certificate.isSelectedTC){
            this.deSelectAll(0);
          }
          if(this.rejected.length > 0 && certificate.isSelectedTC){
            this.deSelectAll(2);
          }
          this.approvedDraft.splice(tcIndex, 1);
          this.approvedDraft.splice(tcIndex, 0, certificate);
          if(this.approvedDraft.every(pt => pt.isSelectedTC === true)){
              this.tcSections[sectionIndex].isSelectedAll = true;
          }else{
              this.tcSections[sectionIndex].isSelectedAll = false;
          }
          if(certificate.isSelectedTC){
            this.pushTCObj(certificate, sectionIndex);
          }else{
              if(this.selectedApprovedDrafts.length > 0){
                const ref = certificate.tranCertificate1RefId!== '' ? certificate.tranCertificate1RefId : certificate.tranCertificate2RefId;
                for(let i= this.selectedApprovedDrafts.length -1; i >=0; i--){
                  if(this.selectedApprovedDrafts[i].tranCertificateRefId === ref){
                    this.selectedApprovedDrafts.splice(i,1);
                  }
                }
              }
              this.selectedOrderIDs = this.selectedApprovedDrafts;
          }
        }
        if(this.selectedOrderIDs.length > 1){
          this.showBulkBar = true;
        }else{
          this.showBulkBar = false;
        }
    }

	private toggleComplianceDoc(index:number): void {
        if((index === 0 && this.pendingTc.length > 0) || (index === 1 && this.uploaded.length > 0 ) || (index === 2 && this.rejected.length > 0 ) ||
            (index === 3 && this.approvedDraft.length > 0 ) || (index === 4 && this.finalTC.length > 0) || (index === 5 && this.archive.length > 0)){
            this.tcSections[index].isCollapse = !this.tcSections[index].isCollapse;
        }
    }

    private sortedList(sort:string, val: string, index:number) {
      let sortingOrders: TCtableModel[]= []
      if(index === 0){
          sortingOrders = this.pendingTc;
      }else if(index === 1){
          sortingOrders = this.uploaded;
      }else if(index === 2){
          sortingOrders = this.rejected;
      }else if(index === 3){
          sortingOrders = this.approvedDraft;
      }else if(index === 4){
        sortingOrders = this.finalTC;
      }else{
        sortingOrders = this.archive;
      }
      sortingOrders.forEach(order => {
          if (order[val] && Array.isArray(order[val])) {
            order[val].sort((a:any, b:any) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            });
          }
        });
      if(val!==''){
          this.tcSections[index].selectedSortOption = val;
          this.tcSections[index].sortType = sort;
          if(this.tcSections[index].selectedSortOption === 'date'){
            this.sortByDate(sortingOrders, index);
          }else{
              sortingOrders = sortingOrders.slice().sort((a, b) => {
              if(this.tcSections[index].selectedSortOption){
                  if(sort === 'asc'){
                      this.tcSections[index].isSortAtoZ = true;
                      this.tcSections[index].isSortZtoA = false;
                    // if(a[this.tcSections[index].selectedSortOption] === null || Array.isArray(a[this.tcSections[index].selectedSortOption]) ? a[this.tcSections[index].selectedSortOption][0].trim() === "" :  a[this.tcSections[index].selectedSortOption].trim() || a[this.tcSections[index].selectedSortOption] === undefined) return -1;
                    // if(b[this.tcSections[index].selectedSortOption] === null || Array.isArray(b[this.tcSections[index].selectedSortOption]) ? b[this.tcSections[index].selectedSortOption][0].trim() === "" :  b[this.tcSections[index].selectedSortOption].trim() || b[this.tcSections[index].selectedSortOption] === undefined) return 1;
                    if(Array.isArray(a[this.tcSections[index].selectedSortOption]) && Array.isArray(b[this.tcSections[index].selectedSortOption])){
                      if (!isNaN(+a[this.tcSections[index].selectedSortOption][0].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption][0].trim())) {
                          return +a[this.tcSections[index].selectedSortOption][0].trim() - +b[this.tcSections[index].selectedSortOption][0].trim();
                        }
                        return a[this.tcSections[index].selectedSortOption][0].trim().localeCompare(b[this.tcSections[index].selectedSortOption][0].trim());
                    }else{
                      if (!isNaN(+a[this.tcSections[index].selectedSortOption].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption].trim())) {
                          return +a[this.tcSections[index].selectedSortOption].trim() - +b[this.tcSections[index].selectedSortOption].trim();
                        }
                        return a[this.tcSections[index].selectedSortOption].trim().localeCompare(b[this.tcSections[index].selectedSortOption].trim());
                    }
                  }else{
                      this.tcSections[index].isSortZtoA = true;
                      this.tcSections[index].isSortAtoZ = false;
                    // if(a[this.tcSections[index].selectedSortOption] === null || Array.isArray(a[this.tcSections[index].selectedSortOption]) ? a[this.tcSections[index].selectedSortOption][0].trim() === "" :  a[this.tcSections[index].selectedSortOption].trim() || a[this.tcSections[index].selectedSortOption] === undefined) return 1;
                    // if(b[this.tcSections[index].selectedSortOption] === null || Array.isArray(b[this.tcSections[index].selectedSortOption]) ? b[this.tcSections[index].selectedSortOption][0].trim() === "" :  b[this.tcSections[index].selectedSortOption].trim() || b[this.tcSections[index].selectedSortOption] === undefined) return -1;
                    if(Array.isArray(a[this.tcSections[index].selectedSortOption]) && Array.isArray(b[this.tcSections[index].selectedSortOption])){
                      if (!isNaN(+a[this.tcSections[index].selectedSortOption][0].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption][0].trim())) {
                          return +b[this.tcSections[index].selectedSortOption][0].trim() - +a[this.tcSections[index].selectedSortOption][0].trim();
                        }
                        return b[this.tcSections[index].selectedSortOption][0].trim().localeCompare(a[this.tcSections[index].selectedSortOption][0].trim());
                    }else{
                      if (!isNaN(+a[this.tcSections[index].selectedSortOption].trim()) && !isNaN(+b[this.tcSections[index].selectedSortOption].trim())) {
                          return +b[this.tcSections[index].selectedSortOption].trim() - +a[this.tcSections[index].selectedSortOption].trim();
                        }
                        return b[this.tcSections[index].selectedSortOption].trim().localeCompare(a[this.tcSections[index].selectedSortOption].trim());
                    }
                  }
              }
          });
        }
        if(index === 0){
          this.pendingTc =  sortingOrders.slice(0, this.pendingTc.length);
        }else if(index === 1){
          this.uploaded = sortingOrders.slice(0, this.uploaded.length);
        }else if(index === 2){
          this.rejected = sortingOrders.slice(0, this.rejected.length);
        }else if(index === 3){
          this.approvedDraft = sortingOrders.slice(0, this.approvedDraft.length);
        }else if(index === 4){
          this.finalTC = sortingOrders.slice(0, this.finalTC.length);
        }else{
          this.archive = sortingOrders.slice(0, this.archive.length);
        }
      }
    }

    private sortByDate(sortingOrders:TCtableModel[], index:number) {
      if(this.tcSections[index].sortType === 'asc'){
          this.tcSections[index].isSortAtoZ = true;
          this.tcSections[index].isSortZtoA = false;
          sortingOrders = sortingOrders.sort((a: TCtableModel, b: TCtableModel) => {
          if (moment(a.delieveryDate).isBefore(b.delieveryDate)) {
            return -1;
          } else if (moment(a.delieveryDate).isAfter(b.delieveryDate)) {
            return 1;
          }
          return 0;
        });
      }else{
          this.tcSections[index].isSortZtoA = true;
          this.tcSections[index].isSortAtoZ = false;
          sortingOrders = sortingOrders.sort((a: TCtableModel, b: TCtableModel) => {
          if (moment(b.delieveryDate).isBefore(a.delieveryDate)) {
            return -1;
          } else if (moment(b.delieveryDate).isAfter(a.delieveryDate)) {
            return 1;
          }
          return 0;
        });
      }
  }

    private get isTCsExist(): boolean {
        if(this.pendingTc.length > 0 || this.rejected.length > 0 || this.finalTC.length > 0 || this.archive.length > 0 || this.uploaded.length > 0 || this.approvedDraft.length > 0){
            return true
        }
        return false;
    }

    private toggleDropdown(index:number) {
      this.tcSections[index].isOpen = !this.tcSections[index].isOpen;
    }

    private openUploadDocModal(certificate:TCtableModel, type:string, index:number): void{
      this.pushTCObj(certificate,index);
      // this.selectedCertificate = certificate;
      this.showUploadDocModal = true;
      this.showBulkBar = false;
      this.pdfDocument = "";
      this.selectedTCType = type;
      this.documentDetails = new TCDocumentModel();
    }

    private async previewDoc(){
        if (this.uploadCertificate.files.type.includes('pdf')) {
            this.pdfDocument = this.uploadCertificate.files.file;
            if (navigator.geolocation) {
              await navigator.geolocation.getCurrentPosition(async (position) => {
                this.uploadCertificate.geoLat = position.coords.latitude;
                this.uploadCertificate.geoLong = position.coords.longitude;
              });
                this.pdfDocument = await this.uploadCertificate.files.arrayBuffer();
            }
          }
        this.showCerts = true;
    }

    private closeUploadModel(): void{
        this.showCerts = false;
        this.showUploadDocModal = false;
        this.unSelectSections();
        this.selectedOrderIDs = [];
        this.uploadCertificate.files = [];
        this.selectedApprovedDrafts = [];
        this.selectedRejected = [];
        this.selectedPending = [];
        this.unSelectSections();
    }

    // private showPreview(): void{
    //     this.selectedOrderIDs = lodash.uniqBy(this.selectedOrderIDs, 'orderId');
    // }

    private showRemovePopup(pt:TCtableModel,index:number): void{
        this.selectedPendingRequestIndex = index;
        this.showDeleteDocModal = true;
        this.isShowSendRemainder = false;
    }

    private async checkForUploadErrors(field: string): Promise<void> {
        const maxSize = 1024 * 1024 * 5;
        if (this.currentView == '') {
          return;
        }
        switch (field) {
          case 'files':
            this.isUploadError.files = 1;

            if (maxSize < this.uploadCertificate.files.size) {
              NotificationHelper.createErrorNotification(
                i18n.t('errors.certificate_upload_failed').toString()
              );
              return;
            }

            if (this.uploadCertificate.files.type.includes('pdf')) {
              this.pdfDocument = this.uploadCertificate.files.file;
              this.isUploadError.files = 0;
              if (navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition(async (position) => {
                  this.uploadCertificate.geoLat = position.coords.latitude;
                  this.uploadCertificate.geoLong = position.coords.longitude;
                  this.isUploadError.geoLat = 0;
                  this.isUploadError.geoLong = 0;
                });
                if (this.currentView == 'viewpdf') {
                  this.pdfDocument =
                    await this.uploadCertificate.files.arrayBuffer();
                }
              }
            }
            break;
          case 'geoLat':
            this.isUploadError.geoLat = 1;
            if (this.uploadCertificate.geoLat !== 0) {
              this.isUploadError.geoLat = 0;
            }
            break;
          case 'geoLong':
            this.isUploadError.geoLong = 1;
            if (this.uploadCertificate.geoLong !== 0) {
              this.isUploadError.geoLong = 0;
            }
            break;
        }
      }

      private async checkAllFieldsForErrors(): Promise<void> {
        await this.checkForUploadErrors('files');
        this.checkForUploadErrors('validfromDate');
        this.checkForUploadErrors('validtoDate');
      }


      private async switchView(view: string): Promise<void> {
        this.selectedOrderIDs = lodash.uniqBy(this.selectedOrderIDs, 'orderId');
      if (view == 'viewpdf' && this.isDocUploading) {
        return;
      }

      if (view == 'viewpdf' && this.uploadCertificate.files) {
        this.currentView = view;
        await this.checkAllFieldsForErrors();
        setTimeout(
          async (app: any) => {
            app.pdfDocument = await app.uploadCertificate.files.arrayBuffer();
          },
          250,
          this
        );
      }
      this.showCerts = true;
      }

    private updateTCTable(): void {
        this.getFilteredOrders();
    }

    private BulkUpload(): void{
      if(!this.checkIsClientUnique()){
        this.showWarnging = true;
      }else{
        this.showUploadDocModal = true;
        if(this.selectedApprovedDrafts.length > 0 && this.selectedPending.length === 0 && this.selectedRejected.length === 0){
          this.selectedTCType = 'UPLOADFINALTC';
        }else{
          if(this.selectedPending .length > 0){
            this.selectedTCType = 'UPLOAD'
          }else{
            this.selectedTCType = 'UPLOADNEW';
          }
        }
      }
      this.showBulkBar = false;
    }

    private checkIsClientUnique(): boolean{
      const firstClientId = this.selectedOrderIDs[0].clientId;
      const certificate = this.selectedOrderIDs[0].tranCertificate;
      const level = this.selectedOrderIDs[0].level
      if(this.selectedOrderIDs.every(obj => obj.clientId === firstClientId) && this.selectedOrderIDs.every(obj => obj.tranCertificate === certificate) &&
        this.selectedOrderIDs.every(obj => obj.level === level)){
        return true;
      }
      return false;
    }

    private collapseSection(){
      if(this.pendingTc.length === 0){
          this.tcSections[0].isCollapse = false;
      }else{
          this.tcSections[0].isCollapse = true;
      }
      if(this.uploaded.length === 0){
          this.tcSections[1].isCollapse = false;
      }else{
          this.tcSections[1].isCollapse = true;
      }
      if(this.rejected.length === 0){
          this.tcSections[2].isCollapse = false;
      }else{
          this.tcSections[2].isCollapse = true;
      }
      if(this.approvedDraft.length === 0){
          this.tcSections[3].isCollapse = false;
      }else{
          this.tcSections[3].isCollapse = true;
      }
      if(this.finalTC.length === 0){
        this.tcSections[4].isCollapse = false;
      }else{
        this.tcSections[4].isCollapse = true;
      }
      if(this.archive.length === 0){
        this.tcSections[5].isCollapse = false;
      }
    }
    private transcationCertificates(index:number): TCtableModel[]{
      if(index === 0){
          return this.pendingTc;
      }else if(index === 1){
          return this.uploaded;
      }else if(index === 2){
          return this.rejected;
      }else if(index === 3){
          return this.approvedDraft;
      }else if(index === 4){
        return this.finalTC;
      }else{
        return this.archive
      }
  }

  private async viewCertificate(certificate: TCtableModel, docIndex: number, selectedTC: string): Promise<void> {
    this.unSelectSections();
    this.selectedCertificate = certificate;
    this.pushTCObj(certificate, docIndex);
    await this.getTCDocument(certificate);
    this.selectedTCType = selectedTC;
    this.showCerts = true;
}

private async getTCDocument(document: TCtableModel): Promise<void> {
  let tcRefID: string = '';
  if(document.tranCertificate1RefId !== ''){
      tcRefID = document.tranCertificate1RefId;
  } else {
      tcRefID = document.tranCertificate2RefId;
  }
  const documentDetails = await this.complianceService.getViewTCDocument(tcRefID);
  if(documentDetails.success){
      this.documentDetails = documentDetails;
      this.rejectedReason = this.documentDetails.tcData.rejectionReason;
      if(document.isDocLoading){
          document.isDocLoading = false;
          this.showRejectionDoc = true;
      }

  }
}

private viewRejectReason(certificate:TCtableModel): void{
  certificate.isDocLoading = true;
  this.getTCDocument(certificate);
}

private pushTCObj(certificate:TCtableModel, index:number):void{
  if(certificate.orderIds.length > 1){
    for(let i=0; i<=certificate.orderIds.length - 1; i++){
      const tcObj = new UploadOrderModel();
      tcObj.orderId = certificate.orderIds[i],
      tcObj.certificate1or2 = certificate.tranCertificate1 !=='' ? "1" : "2",
      tcObj.clientId = certificate.fromCompanyId,
      tcObj.tranCertificate = certificate.tranCertificate1 !=='' ? certificate.tranCertificate1 : certificate.tranCertificate2,
      tcObj.level = certificate.levelTC1 !=='' ? certificate.levelTC1 : certificate.levelTC2,
      tcObj.orderNumber = certificate.orderNumbers[i],
      tcObj.styleNumber = certificate.styleNumbers[i],
      tcObj.colour = certificate.colours[i],
      tcObj.client = certificate.client
      tcObj.isRequestSelected = true;
      tcObj.tranCertificateRefId = certificate.tranCertificate1RefId !=='' ? certificate.tranCertificate1RefId : certificate.tranCertificate2RefId;
      this.pushInToIndexSection(index,tcObj);
    }
  }else{
      const tcObj = new UploadOrderModel();
      tcObj.orderId = certificate.orderIds[0],
      tcObj.certificate1or2 = certificate.tranCertificate1 !=='' ? "1" : "2",
      tcObj.clientId = certificate.fromCompanyId,
      tcObj.tranCertificate = certificate.tranCertificate1 !=='' ? certificate.tranCertificate1 : certificate.tranCertificate2,
      tcObj.level = certificate.levelTC1 !=='' ? certificate.levelTC1 : certificate.levelTC2,
      tcObj.orderNumber = certificate.orderNumbers[0],
      tcObj.styleNumber = certificate.styleNumbers[0],
      tcObj.colour = certificate.colours[0],
      tcObj.client = certificate.client
      tcObj.isRequestSelected = true;
      tcObj.tranCertificateRefId = certificate.tranCertificate1RefId !=='' ? certificate.tranCertificate1RefId : certificate.tranCertificate2RefId;
      // this.selectedOrderIDs.push(tcObj);
      this.pushInToIndexSection(index,tcObj);
  }
  // if(this.selectedOrderIDs.length > 1){
  //   this.showBulkBar = true;
  // }else{
  //   this.showBulkBar = false;
  // }
}

private pushInToIndexSection(index:number, tcObj:UploadOrderModel): void{
  if(index === 0){
    this.selectedPending.push(tcObj);
    this.selectedOrderIDs = [];
    this.selectedOrderIDs = this.selectedPending;
  }else if(index === 2){
    this.selectedRejected.push(tcObj);
    this.selectedOrderIDs = [];
    this.selectedOrderIDs = this.selectedRejected;
  }else{
    this.selectedApprovedDrafts.push(tcObj);
    this.selectedOrderIDs = [];
    this.selectedOrderIDs = this.selectedApprovedDrafts;
  }
}

private async getDocUrl(doc: TCtableModel): Promise<void> {
  await this.getTCDocument(doc);
  if(this.documentDetails.success && this.documentDetails.docUrl !== ''){
      const tcElem = document.getElementById('tcAnchor');
      if(tcElem){
          tcElem.click();
      }
  }
}

private unSelectSections(): void{
  if(this.selectedPending.length > 0){
    this.deSelectAll(0);
  }else if(this.selectedRejected.length > 0){
    this.deSelectAll(2);
  }else{
    this.deSelectAll(3);
  }
  this.showUploadDocModal = false;
}

private get isFinalExist(): boolean {
  if(this.uploaded.findIndex(re => re.tc1Type === TCType.FINALTC || re.tc2Type === TCType.FINALTC)!==-1){
    return true;
  }
  return false;
}

private get isDraftExist(): boolean {
  if(this.uploaded.findIndex(re => re.tc1Type === TCType.DRAFT || re.tc2Type === TCType.DRAFT)!==-1){
    return true;
  }
  return false;
}

private getToolTip(val:string): string {
  if(val.length > 20){
    return val;
  }
  return ''
}

  @Watch("searchFilter", { deep: true })
  private selectedItem(): void {
    if(this.searchFilter!==''){
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      if(this.initialPendingTC.length > 0){
          this.pendingTc = this.initialPendingTC.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
          || req.client.toLowerCase().includes(lowerCaseFilter) || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
          || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter));
      }
      if(this.initialUploaded.length > 0){
          this.uploaded = this.initialUploaded.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
          || req.client.toLowerCase().includes(lowerCaseFilter)
          || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
          || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
          || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
      }
      if(this.finalTC.length > 0){
          this.finalTC = this.initialFinalTC.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
          || req.client.toLowerCase().includes(lowerCaseFilter)
          || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
          || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
          || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
      }
      if(this.archive.length > 0){
          this.archive = this.initialArchive.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
          || req.client.toLowerCase().includes(lowerCaseFilter)
          || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
          || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
          || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
      }
      if(this.initialApprovedDraft.length > 0){
        this.approvedDraft = this.initialApprovedDraft.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
        || req.client.toLowerCase().includes(lowerCaseFilter)
        || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
        || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
        || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
      }
      if(this.initialRejected.length > 0){
        this.rejected = this.initialRejected.filter((req) => req.tranCertificate1.toLowerCase().includes(lowerCaseFilter) || req.tranCertificate2.toLowerCase().includes(lowerCaseFilter)
        || req.client.toLowerCase().includes(lowerCaseFilter)
        || req.brandNames.map(b => b.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNames.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter) || req.styleNumbers.map(s => s.toLowerCase()).join(',').includes(lowerCaseFilter)
        || req.orderNumbers.map(o => o.toLowerCase()).join(',').includes(lowerCaseFilter) || req.colours.map(c => c.toLowerCase()).join(',').includes(lowerCaseFilter) || req.levelTC1.toLocaleLowerCase().includes(lowerCaseFilter) || req.levelTC2.toLocaleLowerCase().includes(lowerCaseFilter)
        || req.tcReferenceNumber.toLocaleLowerCase().includes(lowerCaseFilter));
      }
    }else{
        this.pendingTc = this.initialPendingTC;
        this.uploaded = this.initialUploaded;
        this.finalTC = this.initialFinalTC;
        this.archive = this.initialArchive;
        this.approvedDraft = this.initialApprovedDraft;
        this.rejected = this.initialRejected;
    }
    this.collapseSection();
}

  @Watch("selectedBrand", { deep: true })
  private filterBrand(): void {
      if(this.selectedBrand !== ""){
          if(this.initialPendingTC.length > 0){
              this.pendingTc = this.initialPendingTC.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand));
          }
          if(this.initialUploaded.length > 0){
              this.uploaded = this.initialUploaded.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand));
          }
          if(this.initialFinalTC.length > 0){
              this.finalTC = this.initialFinalTC.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand));
          }
          if(this.initialArchive.length > 0){
              this.archive = this.initialArchive.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand));
          }
          if(this.initialApprovedDraft.length > 0){
            this.approvedDraft = this.initialApprovedDraft.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand));
          }
          if(this.initialRejected.length > 0){
            this.rejected = this.initialRejected.filter(req => req.brandNames.map(b => b.toLowerCase()).join(',').includes(this.selectedBrand));
          }
  }else{
          this.pendingTc = this.initialPendingTC;
          this.uploaded = this.initialUploaded;
          this.finalTC = this.initialFinalTC;
          this.archive = this.initialArchive;
          this.approvedDraft = this.initialApprovedDraft;
          this.rejected = this.initialRejected;
      }
      this.collapseSection();
  }

  @Watch("selectedClient", { deep: true })
  private filterPartner(): void {
      if(this.selectedClient !== ""){
          if(this.initialPendingTC.length > 0){
              this.pendingTc = this.initialPendingTC.filter(req => req.client === this.selectedClient);
          }
          if(this.initialUploaded.length > 0){
              this.uploaded = this.initialUploaded.filter(req => req.client === this.selectedClient);
          }
          if(this.initialFinalTC.length > 0){
              this.finalTC = this.initialFinalTC.filter(req => req.client === this.selectedClient);
          }
          if(this.initialArchive.length > 0){
              this.archive = this.initialArchive.filter(req => req.client === this.selectedClient);
          }
          if(this.initialApprovedDraft.length > 0){
            this.approvedDraft = this.initialApprovedDraft.filter(req => req.client === this.selectedClient);
          }
          if(this.initialRejected.length > 0){
            this.rejected = this.initialRejected.filter(req => req.client === this.selectedClient);
          }
  }else{
          this.pendingTc = this.initialPendingTC;
          this.uploaded = this.initialUploaded;
          this.finalTC = this.initialFinalTC;
          this.archive = this.initialArchive;
          this.approvedDraft = this.initialApprovedDraft;
          this.rejected = this.initialRejected;
      }
      this.collapseSection();
  }

  @Watch("selectedType", { deep: true })
  private filterType(): void {
      if(this.selectedType !== ""){
          const certificates = i18n.t(`enums.certificates_types`) as any;
          const result: DropdownModel[] = [];
          for (const key of Object.keys(certificates)) {
              const certifcateName = certificates[key];
              result.push({ value: key, text: certifcateName });
          }
          const filteredType = result.filter(r => r.value === this.selectedType)[0]
          if(this.initialPendingTC.length > 0){
              this.pendingTc = this.initialPendingTC.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
          }
          if(this.initialUploaded.length > 0){
              this.uploaded = this.initialUploaded.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
          }
          if(this.initialFinalTC.length > 0){
              this.finalTC = this.initialFinalTC.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
          }
          if(this.initialArchive.length > 0){
              this.archive = this.initialArchive.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
          }
          if(this.initialApprovedDraft.length > 0){
            this.approvedDraft = this.initialApprovedDraft.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
        }
        if(this.initialRejected.length > 0){
          this.rejected = this.initialRejected.filter(req => req.tranCertificate1 === filteredType.text || req.tranCertificate2 === filteredType.text);
      }
  }else{
          this.pendingTc = this.initialPendingTC;
          this.uploaded = this.initialUploaded;
          this.finalTC = this.initialFinalTC;
          this.archive = this.initialArchive;
          this.approvedDraft = this.initialApprovedDraft;
          this.rejected = this.initialRejected;
      }
      this.collapseSection();
  }

  @Watch('uploadCertificate.files')
  private checkValidFiles(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('files');
    }
  }

}
