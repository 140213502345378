import { AddressModel } from './addressModel';
import { CompanyCertificateModel } from './companyCertificateModel';
import { CompanyPictureModel } from './companyPictureModel';
import { ContactModel } from './contactModel';

export class CompanyModel {
  public id!: string;
  public companyName!: string;
  public contact!: ContactModel;
  public admin!: ContactModel;
  public companyType?: number;
  public companySubtypes!: number[];
  public companyId!: string;
  public categories!: number[];
  public monthlyCapacity?: number;
  public turnover?: number;
  public employees?: number;
  public maleEmployees?: number;
  public femaleEmployees?: number;
  public brands!: string;
  public productGroups!: number[];
  public profile!: string;
  public officeAddress!: AddressModel[];
  public factoryAddresses!: AddressModel[];
  public factoryAddressDiffers!: boolean;
  public companyNameUpdated!: boolean;
  public profilePictures!: CompanyPictureModel[];
  public social!: CompanyCertificateModel[];
  public sustainability!: CompanyCertificateModel[];

  public constructor() {
    this.id = '';
    this.companyName = '';
    this.contact = new ContactModel();
    this.admin = new ContactModel();
    this.companyType = undefined;
    this.companySubtypes = [];
    this.companyId = '';
    this.categories = [];
    this.brands = '';
    this.productGroups = [];
    this.profile = '';
    this.officeAddress = [];
    this.factoryAddresses = [];
    this.factoryAddressDiffers = false;
    this.profilePictures = [];
  }
}

export class companyResponseModel {
  public exceptionMessage!: string | null;
  public message!: string;
  public result!: string;
  public responseObject!: CompanyModel;
}

export class CompanyNameModel {
  public constructor(id: string, name: string){
      this.ID = id;
      this.name = name;
  }
  public ID!: string;
  public name!: string;
}

export class CompanyNameResponseModel {
  public success!: string;
  public companies!: string;
}
