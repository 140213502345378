import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { ActionTypes } from '@/store/actions/actions';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import store from '@/store';
import lodash from 'lodash';
import { debounce } from 'ts-debounce';

// Components
import MapModal from '@/views/profile/components/mapModal.vue';
import Multiselect from '@/components/input/multiselect.vue';
import CompanyPictures from '@/views/profile/components/companyPictures.vue';
import DropDownInput from '@/components/general/drop-down-input/dropDownInput.vue';
import DropDownEditable from '@/components/general/drop-down-editable/dropDownEditable.vue';
import SmallScreenPopUpMsg from '@/components/general/smallScreenPopUpMsg/smallScreenPopUpMsg.vue';

// Helpers
import { CompanyFormDropdownHelper } from '@/helpers/companyFormDropdownHelper';
import { CryptionHelper } from '@/helpers/cryptionHelper';
import { CountryHelper } from '@/helpers/countryHelper';
import { NotificationHelper } from '@/helpers/notificationHelper';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper'

// Services
import { GeolocationService } from '@/services/geolocationService';
import { InvitationService } from '@/services/invitationService';
import { UserService } from '@/services/userService';
import { SupplierService } from '@/services/supplierService';
import { CountryStateCityService } from '@/services/countryStateCityService';
import { MiddlewareService } from '@/services/middlewareService';
import { UserClient } from '@/clients/userClient';

// Models
import { AddressModel } from '@/models/addressModel';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { ContactModel } from '@/models/contactModel';
import { DropdownModel } from '@/models/dropdownModel';
import { UserPermissionModel } from '@/models/permissionModel';
import { AddressStatus } from '@/models/addressState';
import { CompanyAdminRequestModel } from '@/models/createCompanyUsersModel';
import { SigninSupplierModel } from '@/models/signinSupplierModel';
import { UserModel } from '@/models/userModel';

@Component({
  components: {
    MapModal,
    Multiselect,
    DropDownInput,
    DropDownEditable,
    CompanyPictures,
    SmallScreenPopUpMsg,
  },
  computed: mapGetters(['userRole', 'userPermissions', 'encryptPwd']),
})
export default class CompanyForm extends Vue {
  private changedSettingsCounter: number = 0;

  private supplierService: SupplierService;

  private invitationService: InvitationService;

  private userService: UserService;

  private geolocationService: GeolocationService;

  private middlewareService: MiddlewareService;

  private client: UserClient = new UserClient();

  private cryptionHelper: CryptionHelper;

  private mapboxAccessToken: string = '';

  private admin: ContactModel = new ContactModel();

  private isUploadError: any = { validtoDate: false, validfromDate: false };

  private contact: ContactModel = new ContactModel();

  private company: CompanyModel = new CompanyModel();

  private companyClone: CompanyModel = new CompanyModel();

  private allCategories: DropdownModel[] = [];

  private statesDropdown: any = {};

  private citiesDropdown: any = {};

  private selectedCountryValue: string = '';

  private selectedStateValue: string = '';

  private cscService: CountryStateCityService;

  private allProductGroups: DropdownModel[] = [];

  private companyTypes: DropdownModel[] = [];

  private companySubtypes: DropdownModel[] = [];

  private countries: DropdownModel[] = [];

  private factoryAddresses: AddressModel[] = [new AddressModel()];

  private officeAddress: AddressModel[] = [new AddressModel()];

  private companyAddresses: AddressModel[] = [new AddressModel()];

  private curAddressId: string = '';
  private curAddressTitle: string = '';
  private curAddressDetails: string = '';

  private showDefaultProfileTextModal: boolean = false;

  private isLoading: boolean = false;

  private isSaving: boolean = false;

  private isCompanyTypeDisabled: boolean = false;

  private isMainContactEmailDisabled: boolean = false;

  private isValid: boolean = true;

  private countryError: boolean = false;

  private cityError: boolean = false;

  private stateError: boolean = false;

  private countryErrorList: any = {};

  private cityErrorList: any = {};

  private stateErrorList: any = {};

  private showOfficeAddressMapModal: number = -1;

  private indexShowFactoryAddressMapModal: number = -1;

  private totalCertificates: number = 0;

  private indexOffice: number = -1;

  private indexFactory: number = -1;

  private companyName: string = '';

  private isCurrentAddress: boolean = false;

  private isMobileAddressError: boolean = false;

  private isMobile: boolean = false;

  private officeAddrDisabled: boolean[] = [];

  private isTablet: boolean = false;

  private disableSave: boolean = false;

  private showOfficeAddressMobile: boolean = false;

  private companyPicturesState: string = '';

  private showQrCodeModal: boolean = false;

  private addressAddedRemoved: boolean = false;

  private checkFieldsDebounced: any;

  private checkNameDebounced: any;

  private showConfirmAddressModal: boolean = false;

  private showEditWarningModal: boolean = false;

  private isFactoryAddress: boolean = false;

  private confirmedOfficeAddCount: number = 0;

  private confirmedFactoryAddCount: number = 0;

  private addressType: string = '';

  private officeIndex: number = -1;

  private allAddressCount: string = '';

  private mobileCurrentAddress: number = 1;

  private recentCertificates!: number;

  private allAddressConfirmed: boolean = false;

  private isBrandInvalid: boolean = false;

  private isInvalidAddress: boolean = false;

  private showDisableSingleAddressWarning: boolean = false;

  private showDisableMultipleAddressWarning: boolean = false;

  private disableAddressIndex: number = -1;

  private disableAddressType: string = '';

  private locationError: boolean = false;

  private companyPicturesAvailable: boolean = false;

  private isNewlyInvited: boolean = false;

  private invitationId: string | null = '';

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  private profileSave: string = 'FIRST';

  private encryptPwd!: string;

  private isAccountSave: boolean = false;

  private showSave: boolean = true;

  private isPhoneNonRoman: boolean = false;

  private isEmailNonRoman: boolean = false;

  private isValidType: boolean = true;
  private firstNameError: boolean = false;
  private fNameSpecCharErr: boolean = false;

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.invitationService = new InvitationService();
    this.cscService = new CountryStateCityService();
    this.geolocationService = new GeolocationService();
    this.userService = new UserService();
    this.middlewareService = new MiddlewareService();
    this.cryptionHelper = new CryptionHelper();
  }

  private mounted(): void {
    if (screen.width < 636) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    if (screen.width < 1081) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }
    window.onresize = () => {
      if (window.innerWidth < 636) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      if (window.innerWidth < 1081) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
      localStorage.setItem('isMobile', this.isMobile.toString());
    };
    localStorage.setItem('isMobile', this.isMobile.toString());
  }

  private get types(): DropdownModel[] {
    return this.companyTypes;
  }

  private get subtypes(): DropdownModel[] {
    return this.companySubtypes;
  }

  private get productGroups(): DropdownModel[] {
    return this.allProductGroups;
  }

  private get categories(): DropdownModel[] {
    return this.allCategories;
  }

  private get officeAddressValidation10length(): string {
    if (this.company.factoryAddressDiffers) {
      return 'max:10|roman';
    }
    return 'required|max:10|roman';
  }

  private get officeAddressValidation100length(): string {
    if (this.company.factoryAddressDiffers) {
      return 'max:100|roman';
    }
    return 'required|max:100|roman';
  }

  private get isConfirming(): boolean {
    if (
      (this.company.officeAddress &&
        this.company.officeAddress.some((o) => o.isConfirming)) ||
      this.company.factoryAddresses.some((u) => u.isConfirming)
    ) {
      return true;
    }
    return false;
  }

  private get isCompanyPictureUploaded(): boolean {
    let isUploaded = true;
    const latestCompany: CompanyModel = store.getters.company;
    this.company.officeAddress.forEach((address, index) => {
      if (index === 0) {
        const count = latestCompany.profilePictures.filter(
          (p) =>
            p.addressID === address.id ||
            p.addressID === '' ||
            p.addressID === null
        ).length;
        if (count < 3) {
          isUploaded = false;
        }
      } else {
        if (isUploaded) {
          const count = latestCompany.profilePictures.filter(
            (p) => p.addressID === address.id
          ).length;
          if (count < 3) {
            isUploaded = false;
            return;
          }
        }
      }
    });
    if (isUploaded) {
      this.company.factoryAddresses.forEach((address) => {
        const count = latestCompany.profilePictures.filter(
          (p) => p.addressID === address.id
        ).length;
        if (count < 3) {
          isUploaded = false;
          return;
        }
      });
    }
    return isUploaded;
  }

  private get showReactiveBar(): boolean {
    this.showSave = true;
    let showBar = false;
    if (
      this.showOfficeAddressMobile ||
      this.showQrCodeModal ||
      this.showDisableSingleAddressWarning ||
      this.showDisableMultipleAddressWarning ||
      this.isMobileAddressError
    ) {
      showBar = false;
    } else {
      if (this.changedSettingsCounter > 0 || this.invitationId) {
        showBar = true;
      }
    }
    return showBar;
  }

  private async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.checkNameDebounced = debounce(this.validateName, 250, {
        maxWait: 250,
        isImmediate: true,
      });
      const mapboxTokenModel = await this.geolocationService.getMapboxToken();
      this.mapboxAccessToken = mapboxTokenModel.token;
      this.companyTypes = CompanyFormDropdownHelper.getCompanyTypes();
      this.companySubtypes = CompanyFormDropdownHelper.getCompanySubtypes();
      this.allCategories = CompanyFormDropdownHelper.getCategories();
      this.allProductGroups = CompanyFormDropdownHelper.getProductGroups();
      this.countries = CountryHelper.getCountryList();

      this.invitationId = localStorage.getItem('invitationId');
      if (this.invitationId) {
        this.isMainContactEmailDisabled = true;
        if (store.getters.company != null) {
          this.company = store.getters.company;
        } else {
          this.company =
            await this.invitationService.getSupplierByInvitationIdAsync(
              this.invitationId
            );
          store.commit('setMyAccount', this.company);
        }
        this.isNewlyInvited = true;
      } else {
        this.isCompanyTypeDisabled = true;
        if (this.$store.getters.company != null) {
          this.company = this.$store.getters.company;
        } else {
          await this.$store.dispatch(ActionTypes.SET_MYACCOUNT);
          this.company = this.$store.getters.company;
        }
        const total = this.$store.getters.certificatesCount;
        if (total) {
          this.recentCertificates = total;
        } else {
          this.recentCertificates = 0;
        }
      }
      this.admin = this.company.admin;
      this.contact = this.company.contact;
      this.isCompanyPicturesAvailable();
      this.updateProfilePictures();
      this.countryStateCityValidation();

      this.officeAddress = this.company.officeAddress;
      this.officeAddress.forEach((o) => this.checkAddressDisabled(o));
      this.company.factoryAddresses = this.company.factoryAddresses.filter(
        (f) => f.postal !== ''
      );
      this.factoryAddresses = this.company.factoryAddresses;
      this.factoryAddresses.forEach((f) => this.checkAddressDisabled(f));
      this.companyName = this.company.companyName;
      this.allOfficeAddressConfirmed();
      this.allFactoryAddressConfirmed();
      this.allConfirmedAddress();
      this.sortForDisabledAddresses('OFFICE');
      this.sortForDisabledAddresses('FACTORY');
      this.company.officeAddress = this.officeAddress;
      this.company.factoryAddresses = this.factoryAddresses;
      if (this.invitationId) {
        this.checkAddressLookupAvailable(this.company.officeAddress[0]);
      }
      store.commit('setMyAccount', this.company);
      const companyState = this.$store.getters.company as CompanyModel;
      if (!this.invitationId) {
        let sustainableCount = 0;
        let socialCount = 0;
        for (
          let index = 0;
          index < companyState.sustainability.length;
          index++
        ) {
          if (
            companyState.sustainability[index].fileType !== 'image/jpeg' &&
            companyState.sustainability[index].fileType !== null
          ) {
            sustainableCount++;
          }
        }
        for (let index = 0; index < companyState.social.length; index++) {
          if (
            companyState.social[index].fileType !== 'image/jpeg' &&
            companyState.social[index].fileType !== null
          ) {
            socialCount++;
          }
        }
        this.totalCertificates =
          socialCount + sustainableCount + this.recentCertificates;
      } else {
        this.totalCertificates = 0;
      }

      this.checkFieldsDebounced = debounce(this.validateField, 250, {
        maxWait: 250,
        isImmediate: true,
      });
    } catch (e: any) {
      if (e.statuscodeBackingField == 500) {
        localStorage.setItem('invitationId', '');
        this.$router.push({
          name: 'Signin',
          params: { invitationError: '1' },
        });
      }
    } finally {
      this.companyClone = lodash.cloneDeep(this.company);
      this.isLoading = false;
    }
  }

  private isCompanyPicturesAvailable(): void {
    const company = this.$store.getters.company;
    if (company.profilePictures.length > 2) {
      this.companyPicturesAvailable = true;
    }
  }

  private updateProfilePictures(): void {
    if (this.company && this.company.profilePictures.length > 0) {
      for (let i = 0; i < this.company.profilePictures.length; i++) {
        if (
          this.company.profilePictures[i].addressID === '' ||
          this.company.profilePictures[i].addressID === null
        ) {
          this.company.profilePictures[i].addressID =
            this.company.officeAddress[0].id;
        }
      }
    }
  }

  private allConfirmedAddress(): void {
    let confirmedOfficeAdd = 0;
    let confirmedFactoryAdd = 0;
    this.allAddressCount = '';
    const totalAddressCount =
      this.officeAddress.length + this.factoryAddresses.length;

    if (this.officeAddress.length > 0) {
      confirmedOfficeAdd = this.officeAddress.filter(
        (o) => o.isConfirmed === true
      ).length;
      this.allAddressCount = `${confirmedOfficeAdd}/${
        this.officeAddress.length
      }${i18n.t('pages.profile.office_add_confirmed')}`;
    }

    if (this.factoryAddresses.length > 0) {
      confirmedFactoryAdd = this.factoryAddresses.filter(
        (f) => f.isConfirmed === true
      ).length;
      if (this.officeAddress.length > 0) {
        this.allAddressCount =
          this.allAddressCount +
          `${i18n.t('pages.profile.and')} ${confirmedFactoryAdd}/${
            this.factoryAddresses.length
          }${i18n.t('pages.profile.factory_add_confirmed')}`;
      } else {
        this.allAddressCount = `${confirmedFactoryAdd}/${
          this.factoryAddresses.length
        }${i18n.t('pages.profile.factory_add_confirmed')}`;
      }

      if (this.officeAddress.length > 0 && this.factoryAddresses.length > 0) {
        this.allAddressCount =
          `${confirmedOfficeAdd}/${this.officeAddress.length} ${i18n.t(
            'pages.profile.office'
          )} ` +
          `${i18n.t('pages.profile.and')} ${confirmedFactoryAdd}/${
            this.factoryAddresses.length
          }${i18n.t('pages.profile.factory_add_confirmed')}`;
      }
    }

    this.allAddressConfirmed =
      totalAddressCount === confirmedOfficeAdd + confirmedFactoryAdd
        ? true
        : false;
  }

  // TTD-4477, for adding validations on firstname
  private validateName(event: any, type: string): void {
    var specials=/[@()[\];:<>, ]/;
    switch (type) {
      case "firstName":
        if (event.length < 2) {
            this.firstNameError = true;
        } else {
            this.firstNameError = false;
        }
        if (specials.test(event) && !(event.length < 2)) {
            this.fNameSpecCharErr = true;
        } else {
            this.fNameSpecCharErr = false;
        }
      break;

      default:
        break;
    }
  }

  // TTD-4477, for adding validations on firstname
  private get relatedValidations(): boolean {
    return this.firstNameError || this.fNameSpecCharErr;
  }

  private async validateField(
    event: any,
    fieldtype: string,
    addresstype: string,
    index: number
  ): Promise<void> {
    const cityDropdownArray = this.$refs[`city_${addresstype}_${index}`] as any;
    const stateDropdownArray = this.$refs[
      `state_${addresstype}_${index}`
    ] as any;

    if (cityDropdownArray.length == 0 || stateDropdownArray.length == 0) {
      return;
    }
    const stateDropdown = stateDropdownArray[0];
    const cityDropdown = cityDropdownArray[0];

    switch (fieldtype) {
      case 'country':
        switch (addresstype) {
          case 'office':
            this.officeAddress[index].country = '';
            this.officeAddress[index].countryInFull = '';
            this.officeAddress[index].state = '';
            this.officeAddress[index].city = '';
            break;

          case 'factory':
            this.factoryAddresses[index].country = '';
            this.factoryAddresses[index].countryInFull = '';
            this.factoryAddresses[index].state = '';
            this.factoryAddresses[index].city = '';
            break;
        }

        this.countryErrorList[`country_${addresstype}_${index}`] =
          event.fieldKey.length < 2 ? true : false;

        this.statesDropdown[`state_${addresstype}_${index}`] = [
          { value: '', text: i18n.t('pages.invitation.loading').toString() },
        ];
        this.citiesDropdown[`city_${addresstype}_${index}`] = [];
        stateDropdown.clearValues();
        cityDropdown.clearValues();

        if (
          this.countryErrorList[`country_${addresstype}_${index}`] === false
        ) {
          const countriesList = await this.cscService.getAllCountries();
          const country = countriesList.find(
            (x: { iso2: any }) => x.iso2 == event.fieldKey
          );

          let states = [];
          if (country !== undefined) {
            switch (addresstype) {
              case 'office':
                this.officeAddress[index].country = event.fieldKey;
                states = await this.cscService.getStatesOfCountry(
                  this.officeAddress[index].country
                );
                break;

              case 'factory':
                this.factoryAddresses[index].country = event.fieldKey;
                states = await this.cscService.getStatesOfCountry(
                  this.factoryAddresses[index].country
                );
                break;
            }

            this.statesDropdown[`state_${addresstype}_${index}`] = [];
            if (states !== undefined) {
              const stateList: DropdownModel[] = [];
              states.forEach((state: { iso2: string; name: string }) => {
                stateList.push({
                  value: state.iso2,
                  text: state.name,
                });
              });
              this.statesDropdown[`state_${addresstype}_${index}`] = stateList;
            }

            if (!states || states.length == 0) {
              this.statesDropdown[`state_${addresstype}_${index}`] = [
                {
                  value: '',
                  text: i18n.t('errors.no_records_found').toString(),
                },
              ];
            }
          } else {
            this.statesDropdown[`state_${addresstype}_${index}`] = [];
          }
        } else {
          this.statesDropdown[`state_${addresstype}_${index}`] = [];
        }
        this.statesDropdown = Object.assign({}, this.statesDropdown, {});
        break;

      case 'state':
        if (
          (this.statesDropdown[`state_${addresstype}_${index}`] === undefined &&
            !event.fieldKey) ||
          event.fieldKey.length == 0
        ) {
          let country =
            addresstype == 'office' ? this.officeAddress[index].country : '';
          country =
            addresstype == 'factory'
              ? this.factoryAddresses[index].country
              : country;

          let countryInFull =
            addresstype == 'office'
              ? this.officeAddress[index].countryInFull
              : '';
          countryInFull =
            addresstype == 'factory'
              ? this.factoryAddresses[index].countryInFull
              : countryInFull;

          this.validateField(
            { fieldKey: country, fieldValue: countryInFull },
            'country',
            addresstype,
            index
          );
          return;
        }

        if (
          (!event.fieldKey || event.fieldKey.length == 0) &&
          this.statesDropdown[`state_${addresstype}_${index}`].length > 0
        ) {
          this.stateErrorList[`state_${addresstype}_${index}`] =
            event.fieldValue == i18n.t('errors.no_records_found').toString() ||
            event.fieldValue == i18n.t('pages.invitation.loading').toString()
              ? true
              : false;
          return;
        }

        switch (addresstype) {
          case 'office':
            this.officeAddress[index].city = '';
            break;

          case 'factory':
            this.factoryAddresses[index].city = '';
            break;
        }

        this.stateErrorList[`state_${addresstype}_${index}`] =
          event.fieldValue.length < 2 ? true : false;

        this.citiesDropdown[`city_${addresstype}_${index}`] = [
          { value: '', text: i18n.t('pages.invitation.loading').toString() },
        ];
        cityDropdown.clearValues();

        if (this.stateErrorList[`state_${addresstype}_${index}`] === false) {
          let country =
            addresstype == 'office' ? this.officeAddress[index].country : '';
          country =
            addresstype == 'factory'
              ? this.factoryAddresses[index].country
              : country;

          switch (addresstype) {
            case 'office':
              this.officeAddress[index].state = event.fieldValue;
              break;

            case 'factory':
              this.factoryAddresses[index].state = event.fieldValue;
              break;
          }

          if (event.fieldKey && event.fieldKey.length > 0) {
            const cities = await this.cscService.getCitiesOfState(
              country,
              event.fieldKey
            );
            if (cities !== undefined) {
              const cityList: DropdownModel[] = [];
              cities.forEach((city: { id: any; name: any }) => {
                cityList.push({ value: city.id, text: city.name });
              });
              this.citiesDropdown[`city_${addresstype}_${index}`] = cityList;
            }
          } else {
            this.citiesDropdown[`city_${addresstype}_${index}`] = [];
          }
        } else {
          this.citiesDropdown[`city_${addresstype}_${index}`] = [];
        }
        this.citiesDropdown = Object.assign({}, this.citiesDropdown, {});
        this.stateErrorList = Object.assign({}, this.stateErrorList, {});
        break;

      case 'city':
        if (addresstype == 'office') {
          if (event.fieldValue == '') {
            this.officeAddress[index].city = '';
          }
        } else {
          if (event.fieldValue == '') {
            this.factoryAddresses[index].city = '';
          }
        }
        if (this.citiesDropdown[`city_${addresstype}_${index}`] === undefined) {
          let country =
            addresstype == 'office' ? this.officeAddress[index].country : '';
          country =
            addresstype == 'factory'
              ? this.factoryAddresses[index].country
              : country;

          let state =
            addresstype == 'office' ? this.officeAddress[index].state : '';
          state =
            addresstype == 'factory'
              ? this.factoryAddresses[index].state
              : state;

          if (
            this.statesDropdown[`state_${addresstype}_${index}`] === undefined
          ) {
            const states = await this.cscService.getStatesOfCountry(country);
            this.statesDropdown[`state_${addresstype}_${index}`] = [];
            if (states !== undefined) {
              const stateList: DropdownModel[] = [];
              states.forEach((state: { iso2: string; name: string }) => {
                stateList.push({
                  value: state.iso2,
                  text: state.name,
                });
              });
              this.statesDropdown[`state_${addresstype}_${index}`] = stateList;
            }
          }

          if (
            this.statesDropdown[`state_${addresstype}_${index}`] === undefined
          ) {
            return;
          }

          const stateKeyValue = this.statesDropdown[
            `state_${addresstype}_${index}`
          ].filter(
            (item: { value: string; text: string }) => item.text == state
          );
          let cities = undefined;
          if (stateKeyValue.length > 0) {
            cities = await this.cscService.getCitiesOfState(
              country,
              stateKeyValue.pop().value
            );
          }

          if (cities !== undefined) {
            const cityList: DropdownModel[] = [];
            cities.forEach((city: { id: any; name: any }) => {
              cityList.push({ value: city.id, text: city.name });
            });
            this.citiesDropdown[`city_${addresstype}_${index}`] = cityList;
          }
          this.citiesDropdown = Object.assign({}, this.citiesDropdown, {});
        }

        this.cityErrorList[`city_${addresstype}_${index}`] =
          event.fieldValue.length < 2 ||
          event.fieldValue == i18n.t('errors.no_records_found').toString()
            ? true
            : false;
        if (this.cityErrorList[`city_${addresstype}_${index}`] === false) {
          switch (addresstype) {
            case 'office':
              this.officeAddress[index].city = event.fieldValue;
              break;

            case 'factory':
              this.factoryAddresses[index].city = event.fieldValue;
              break;
          }
        }
        this.cityErrorList = Object.assign({}, this.cityErrorList, {});
        break;
      default:
        break;
    }
    this.checkLocationError();
    if (addresstype == 'factory') {
      this.checkAddressDisabled(this.factoryAddresses[index]);
      this.checkAddressLookupAvailable(this.factoryAddresses[index], true);
    } else {
      this.checkAddressDisabled(this.officeAddress[index]);
      this.checkAddressLookupAvailable(this.officeAddress[index], true);
    }
    this.countryStateCityValidation();
    this.compareCompanyClone();
  }

  private factoryAddrDiffersCheck(): void {
    if (this.company.factoryAddressDiffers) {
      if (this.company.factoryAddresses.length === 0) {
        this.factoryAddresses.push(new AddressModel());
      }
    }
    this.addressAddedRemoved = true;
  }

  private openQrCodeModal(): void {
    if (!this.showQrCodeModal) {
      this.showQrCodeModal = true;
    }
  }

  private closeQrCodeModal(): void {
    if (this.showQrCodeModal) {
      this.showQrCodeModal = false;
    }
  }

  private openFirstOfficeAddressAndTakePictures(): void {
    if (this.officeAddress.length > 0 || this.factoryAddresses.length > 0) {
      const address =
        this.officeAddress.length > 0
          ? this.officeAddress[0]
          : this.factoryAddresses[0];
      const address_type =
        this.officeAddress.length > 0 ? 'office-address' : 'factory-address';
      this.updateAddressMobile(address.id, address_type, 1);
      setTimeout(
        (app: any) => {
          app.companyPicturesState = 'upload';
        },
        200,
        this
      );
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.profile.add_factory_address').toString()
      );
    }
  }

  private updateAddressMobile(
    addressId: string,
    addressType: string,
    position: number
  ): void {
    if (!this.isAdmin) {
      return;
    }
    if (position <= 0) {
      return;
    }
    if (addressType === 'office-address') {
      if (this.officeAddress[position - 1].addressStatus === 'DISABLED') {
        return;
      }
    } else {
      if (this.factoryAddresses[position - 1].addressStatus === 'DISABLED') {
        return;
      }
    }
    let addressDetails: AddressModel;
    if (!this.showOfficeAddressMobile) {
      this.curAddressId = addressId;
      this.curAddressTitle =
        addressType == 'factory-address'
          ? i18n
              .t('pages.profile.factory_address', [
                this.factoryAddresses[position - 1].sequence + 1,
              ])
              .toString()
          : i18n
              .t('pages.profile.office_address', [
                this.officeAddress[position - 1].sequence + 1,
              ])
              .toString();
      if (addressType == 'office-address') {
        addressDetails = this.officeAddress[position - 1];
      } else {
        if (position <= 0) {
          return;
        }
        addressDetails = this.factoryAddresses[position - 1];
      }

      this.curAddressDetails = `${addressDetails.line1}`;
      if (addressDetails.line2.trim().length > 0) {
        this.curAddressDetails += `, ${addressDetails.line2}`;
      }
      if (addressDetails.postal.trim().length > 0) {
        this.curAddressDetails += `, ${addressDetails.postal} `;
      } else {
        this.curAddressDetails += `, `;
      }
      if (addressDetails.city.trim().length > 0) {
        this.curAddressDetails += `${addressDetails.city}`;
      }
      if (addressDetails.state.trim().length > 0) {
        this.curAddressDetails += `, ${addressDetails.state}`;
      }
      this.curAddressDetails += `, ${i18n
        .t('countries.' + addressDetails.country)
        .toString()}`;
      this.showOfficeAddressMobile = true;
    }
  }

  private closeOfficeAddressMobile(): void {
    if (this.showOfficeAddressMobile) {
      this.showOfficeAddressMobile = false;
      this.companyPicturesState = '';
      this.curAddressId = '';
      this.curAddressTitle = '';
      this.curAddressDetails = '';
    }
  }

  private checkAddressDisabled(address: AddressModel): void {
    const invitationId = localStorage.getItem('invitationId');
    if (address.country !== null || address.country !== '') {
      this.countries.forEach((c) => {
        if (c.value == address.country) {
          address.countryInFull = c.text;
        }
      });
    } else {
      address.countryInFull = '';
    }
    if (invitationId) {
      address.disabled = false;
      address.lookupAvailable = true;
    } else {
      if (
        ((address.geoLong == null || address.geoLong === 0) &&
          (address.geoLat == null || address.geoLat === 0)) ||
        address.forceEnabled
      ) {
        address.disabled = false;
      } else {
        address.disabled = true;
      }

      if (
        address.city == null ||
        address.country == null ||
        address.state == null ||
        address.city == '' ||
        address.country == '' ||
        address.state == '' ||
        address.line1 == null ||
        address.officeName == null ||
        address.postal == null ||
        address.companySubtypes.length == 0
      ) {
        address.lookupAvailable = false;
      } else {
        address.lookupAvailable = true;
      }
      if (address.isConfirmed || address.disabled) {
        address.lookupAvailable = false;
      }
    }

    address.confirmError = false;
  }

  private updateStore(): void {
    this.company.officeAddress = this.officeAddress;
    this.company.factoryAddresses = this.factoryAddresses;
    store.commit('setMyAccount', this.company);
  }

  private checkAddressLookupAvailable(
    address: AddressModel,
    toCheckConfirmed?: boolean
  ): void {
    if (toCheckConfirmed) {
      if (
        address.line1 == '' ||
        address.postal === '' ||
        address.city === '' ||
        address.country === '' ||
        address.state === ''
      ) {
        address.lookupAvailable = false;
        address.isEdited = false;
      } else {
        address.lookupAvailable = true;
        address.isEdited = true;
      }
    } else if (
      address.line1 === '' ||
      address.postal === '' ||
      address.city === '' ||
      address.country === '' ||
      address.state === '' ||
      (address.geoLong != null && address.geoLong !== 0) ||
      (address.geoLat != null && address.geoLat !== 0)
    ) {
      address.lookupAvailable = false;
    } else {
      address.lookupAvailable = true;
    }
  }

  private setUserInformation(): void {
    this.contact = this.company.contact;
  }

  private addFactoryAddress(): void {
    this.scrollToBottom();
    this.addressAddedRemoved = true;
    this.factoryAddresses.push(new AddressModel());
    this.factoryAddresses[this.factoryAddresses.length - 1].sequence =
      this.factoryAddresses.length - 1;
  }

  private removeFactoryAddress(index: number): void {
    this.addressAddedRemoved = true;
    this.factoryAddresses.splice(index, 1);
  }

  private addOfficeAddress(): void {
    this.scrollToBottom();
    this.addressAddedRemoved = true;
    this.officeAddress.push(new AddressModel());
    this.officeAddress[this.officeAddress.length - 1].sequence =
      this.officeAddress.length - 1;
  }

  private removeOfficeAddress(index: number): void {
    this.addressAddedRemoved = true;
    this.officeAddress.splice(index, 1);
  }

  private openDefaultProfileTextModal(): void {
    this.showDefaultProfileTextModal = true;
  }

  private closeDefaultProfileTextModal(): void {
    this.showDefaultProfileTextModal = false;
  }

  private selectTemplate(template: number): void {
    const profileText = document.getElementById(
      `profile-template-${template}`
    ) as HTMLElement;
    this.company.profile = profileText.innerHTML.trim();
    this.closeDefaultProfileTextModal();
    this.compareCompanyClone();
  }

  private confirmOfficeAddress(index: number): void {
    this.companyAddresses = [];
    this.officeAddress[index].isOfficeAddress = true;
    this.companyAddresses.push(this.officeAddress[index]);
    this.showConfirmAddressModal = true;
  }

  private confirmAddress(index: number, addressType: string): void {
    if (this.confirmGeolocationPermission === 1 || this.invitationId !== null) {
      this.companyAddresses = [];
      let address: AddressModel = new AddressModel();
      let allConfirmed: boolean = true;
      switch (addressType) {
        case 'ALL':
          this.officeAddress.forEach((o) => {
            if (!o.isConfirmed) {
              allConfirmed = false;
              o.isOfficeAddress = true;
              this.companyAddresses.push(o);
            }
          });
          this.factoryAddresses.forEach((f) => {
            if (!f.isConfirmed) {
              allConfirmed = false;
              f.isOfficeAddress = false;
              this.companyAddresses.push(f);
            }
          });
          break;
        case 'OFFICE':
          address = this.officeAddress[index];
          allConfirmed = false;
          address.isOfficeAddress = true;
          this.companyAddresses.push(address);
          break;

        case 'FACTORY':
          address = this.factoryAddresses[index];
          allConfirmed = false;
          address.isOfficeAddress = false;
          this.companyAddresses.push(address);
          break;
      }
      if (allConfirmed) {
        return;
      }
      this.addressType = addressType;
      this.officeIndex = index;
      this.showConfirmAddressModal = true;
    }
  }

  private confirmAllLocation(): void {
    this.companyAddresses = [];
    this.officeAddress.forEach((o) => {
      if (o.geoLat === 0 && o.geoLong === 0) {
        o.isOfficeAddress = true;
        this.companyAddresses.push(o);
      }
    });
    this.factoryAddresses.forEach((f) => {
      if (f.geoLat === 0 && f.geoLong === 0) {
        f.isOfficeAddress = false;
        this.companyAddresses.push(f);
      }
    });
    this.showConfirmAddressModal = true;
  }

  private closeMapModal(): void {
    this.showConfirmAddressModal = false;
  }

  private companySubtypeSelected(types: number[]): void {
    this.company.companySubtypes = types;
    this.compareCompanyClone();
  }

  private addressSubtypeSelected(
    types: number[],
    addressType: string | null,
    officeIndex: number
  ): void {
    if (addressType == 'factory') {
      this.factoryAddresses[officeIndex].companySubtypes = lodash.uniq(types);

      if (types.length == 0) {
        this.factoryAddresses[officeIndex].lookupAvailable = false;
      }
    } else {
      this.officeAddress[officeIndex].companySubtypes = lodash.uniq(types);

      if (types.length == 0) {
        this.officeAddress[officeIndex].lookupAvailable = false;
      }
    }
    this.compareCompanyClone();
  }

  private categorySelected(categories: number[]): void {
    this.company.categories = categories;
    this.compareCompanyClone();
  }

  private productGroupSelected(productGroups: number[]): void {
    this.company.productGroups = productGroups;
    this.compareCompanyClone();
  }

  private allOfficeAddressConfirmed(): void {
    for (let index = 0; index < this.officeAddress.length; index++) {
      const addr = this.officeAddress[index];
      if (
        addr.geoLong != null &&
        addr.geoLong !== 0 &&
        addr.geoLat != null &&
        addr.geoLat !== 0 &&
        addr.postal !== ''
      ) {
        this.officeAddress[index].isConfirmed = true;
        this.officeAddress[index].disabled = true;
        this.officeAddress[index].forceEnabled = false;
        this.officeAddrDisabled.push(true);
      } else {
        this.officeAddress[index].isConfirmed = false;
        this.officeAddress[index].disabled = false;
        this.officeAddress[index].forceEnabled = true;
        this.officeAddrDisabled.push(false);
      }
      if (this.officeAddress.findIndex((o) => o.sequence !== 0) === -1) {
        this.officeAddress[index].sequence = index;
      }
    }
  }

  private allFactoryAddressConfirmed(): void {
    for (let index = 0; index < this.factoryAddresses.length; index++) {
      const addr = this.factoryAddresses[index];
      if (
        addr.geoLong != null &&
        addr.geoLong !== 0 &&
        addr.geoLat != null &&
        addr.geoLat !== 0 &&
        addr.postal !== ''
      ) {
        this.factoryAddresses[index].isConfirmed = true;
        this.factoryAddresses[index].disabled = true;
        this.factoryAddresses[index].forceEnabled = false;
      } else {
        this.factoryAddresses[index].isConfirmed = false;
        this.factoryAddresses[index].disabled = false;
        this.factoryAddresses[index].forceEnabled = true;
      }
      if (this.factoryAddresses.findIndex((o) => o.sequence !== 0) === -1) {
        this.factoryAddresses[index].sequence = index;
      }
    }
  }

  private enableOfficeAddress(index: number): void {
    this.indexOffice = index;
    this.showEditWarningModal = true;
  }

  private enableOfficeAddressYes(): void {
    this.showEditWarningModal = false;
    const index = this.indexOffice;
    const officeAddress = this.officeAddress[index];
    officeAddress.disabled = false;
    officeAddress.forceEnabled = true;
    this.officeAddress[index] = officeAddress;
    const add_index = index;
    this.officeAddress.splice(index, 1);
    this.officeAddress.splice(add_index, 0, officeAddress);
    this.indexOffice = -1;
    this.allConfirmedAddress();
  }

  private enableOfficeAddressNo(): void {
    this.showEditWarningModal = false;
    this.indexOffice = -1;
  }

  private enableFactoryAddress(index: number): void {
    this.isFactoryAddress = true;
    this.indexFactory = index;
    this.showEditWarningModal = true;
  }

  private enableFactoryAddressYes(): void {
    this.showEditWarningModal = false;
    const index = this.indexFactory;
    const factoryAddress = this.factoryAddresses[index];
    factoryAddress.disabled = false;
    factoryAddress.forceEnabled = true;
    this.factoryAddresses[index] = factoryAddress;
    const add_index = index;
    this.factoryAddresses.splice(index, 1);
    this.factoryAddresses.splice(add_index, 0, factoryAddress);
    this.indexFactory = -1;
    this.isFactoryAddress = false;
    this.allConfirmedAddress();
  }

  private enableFactoryAddressNo(): void {
    this.showEditWarningModal = false;
    this.isFactoryAddress = false;
    this.indexFactory = -1;
  }

  private isAddressConfirmed(address: AddressModel): boolean {
    return (
      address.geoLong != null &&
      address.geoLong !== 0 &&
      address.geoLat != null &&
      address.geoLat !== 0 &&
      address.isConfirmed === true
    );
  }

  private async addressConfirmed(addresses: AddressModel[]): Promise<void> {
    try {
      switch (this.addressType) {
        case 'ALL':
          addresses.forEach((a) => {
            if (a.isOfficeAddress) {
              const index = this.officeAddress.findIndex(
                (o) =>
                  o.officeName === a.officeName &&
                  o.line1 === a.line1 &&
                  o.line2 === a.line2
              );
              a.forceEnabled = false;
              a.isEdited = false;
              this.officeAddress[index].confirmError = false;
              this.officeAddress.splice(index, 1);
              this.officeAddress.splice(index, 0, a);
            } else {
              const index = this.factoryAddresses.findIndex(
                (o) =>
                  o.officeName === a.officeName &&
                  o.line1 === a.line1 &&
                  o.line2 === a.line2
              );
              a.forceEnabled = false;
              a.isEdited = false;
              this.factoryAddresses[index].confirmError = false;
              this.factoryAddresses.splice(index, 1);
              this.factoryAddresses.splice(index, 0, a);
            }
          });
          this.allConfirmedAddress();
          break;
        case 'OFFICE':
          addresses.forEach((a) => {
            const index = this.officeAddress.findIndex(
              (o) =>
                o.officeName === a.officeName &&
                o.line1 === a.line1 &&
                o.line2 === a.line2
            );
            a.forceEnabled = false;
            a.isEdited = false;
            this.officeAddress[index].confirmError = false;
            this.officeAddress.splice(index, 1);
            this.officeAddress.splice(index, 0, a);
          });
          break;
        case 'FACTORY':
          addresses.forEach((a) => {
            const index = this.factoryAddresses.findIndex(
              (o) =>
                o.officeName === a.officeName &&
                o.line1 === a.line1 &&
                o.line2 === a.line2
            );
            a.forceEnabled = false;
            a.isEdited = false;
            this.factoryAddresses[index].confirmError = false;
            this.factoryAddresses.splice(index, 1);
            this.factoryAddresses.splice(index, 0, a);
          });
          break;
      }
      this.closeMapModal();
      this.allConfirmedAddress();
    } catch {
      //
    } finally {
      //
    }
  }

  private checkLocationError(): void {
    let isError = false;
    this.officeAddress.forEach((o) => {
      if (o.country == '' || o.country == null) {
        o.countryError = true;
        isError = true;
      }
      if (o.state == '' || o.state == null) {
        o.stateError = true;
        isError = true;
      }
      if (o.city == '' || o.city == null) {
        o.cityError = true;
        isError = true;
      }
    });
    if (this.company.factoryAddressDiffers) {
      this.factoryAddresses.forEach((f) => {
        if (f.country == '' || f.country == null) {
          f.countryError = true;
          isError = true;
        }
        if (f.state == '' || f.state == null) {
          f.stateError = true;
          isError = true;
        }
        if (f.city == '' || f.city == null) {
          f.cityError = true;
          isError = true;
        }
      });
    }
    if (isError && !this.isMobile) {
      this.locationError = true;
    } else {
      this.locationError = false;
    }
  }

  private addressConfirmError(): boolean {
    let addressNotConfirmed = false;
    this.officeAddress.forEach((o) => {
      if (o.lookupAvailable) {
        o.confirmError = true;
        addressNotConfirmed = true;
      }
    });
    this.factoryAddresses.forEach((f) => {
      if (f.lookupAvailable) {
        f.confirmError = true;
        addressNotConfirmed = true;
      }
    });
    return addressNotConfirmed;
  }

  private async save(): Promise<void> {
    try {
      const observer = this.$refs.observer as InstanceType<
        typeof ValidationObserver
      >;
      let valid = await observer.validate();
      if (this.company.companyType === 0) {
        valid = false;
        this.isValidType = false;
      }
      if (!valid) {
        this.isValid = false;
        return;
      }
      if (this.addressConfirmError()) {
        return;
      }

      this.checkLocationError();

      if (this.locationError && !this.isMobile) {
        return;
      }
      this.contact.phone = this.contact.phone.trim();
      if (this.contact.phone.length < 10) {
        return;
      }

      this.admin.firstName = this.admin.firstName.trim();
      this.admin.lastName = this.admin.lastName.trim();
      this.admin.email = this.admin.email.trim();
      this.admin.phone = this.admin.phone.trim();

      this.contact.firstName = this.contact.firstName.trim();
      this.contact.lastName = this.contact.lastName.trim();
      this.contact.email = this.contact.email.trim();

      this.isSaving = true;
      this.company.admin = this.admin;
      this.company.contact = this.contact;
      this.company.officeAddress = this.officeAddress;

      if (this.company.factoryAddressDiffers) {
        this.company.factoryAddresses = this.factoryAddresses;
      } else {
        this.company.factoryAddresses = store.getters.savedFactoryAddresses;
      }

      let isAddressInComplete: Boolean = false;
      this.company.officeAddress.forEach((address) => {
        isAddressInComplete =
          address.line1 == '' || address.line1 == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.postal == '' || address.postal == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.country == '' || address.country == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.state == '' || address.state == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.city == '' || address.city == null
            ? true
            : isAddressInComplete;
        address.officeName = address.officeName.trim();
        address.line1 = address.line1.trim();
        address.line2 = address.line2.trim();
        address.postal = address.postal.trim();
      });

      this.company.factoryAddresses.forEach((address) => {
        isAddressInComplete =
          address.line1 == '' || address.line1 == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.postal == '' || address.postal == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.country == '' || address.country == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.state == '' || address.state == null
            ? true
            : isAddressInComplete;
        isAddressInComplete =
          address.city == '' || address.city == null
            ? true
            : isAddressInComplete;
        address.officeName = address.officeName.trim();
        address.line1 = address.line1.trim();
        address.line2 = address.line2.trim();
        address.postal = address.postal.trim();
      });

      const invitationId = localStorage.getItem('invitationId');

      if (
        invitationId &&
        this.company.factoryAddressDiffers &&
        isAddressInComplete &&
        this.company.factoryAddresses.length == 1
      ) {
        const officeAddress = this.company.officeAddress[0];
        this.company.factoryAddresses[0].line1 = officeAddress.line1;
        this.company.factoryAddresses[0].line2 = officeAddress.line2;
        this.company.factoryAddresses[0].postal = officeAddress.postal;
        this.company.factoryAddresses[0].city = officeAddress.city;
        this.company.factoryAddresses[0].state = officeAddress.state;
        this.company.factoryAddresses[0].country = officeAddress.country;
      }

      // check if company name has been changed
      if (this.company.companyName !== this.companyName) {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        user.companyName = this.company.companyName;
        localStorage.setItem('user', JSON.stringify(user));
        this.company.companyNameUpdated = true;
      } else {
        this.company.companyNameUpdated = false;
      }

      this.company.companyName = this.company.companyName.trim();
      this.company.brands = this.company.brands.trim();
      this.company.profile = this.company.profile.trim();
      this.company.contact.firstName = this.company.contact.firstName.trim();
      this.company.contact.lastName = this.company.contact.lastName.trim();
      this.company.contact.email = this.company.contact.email.trim();
      this.company.contact.phone = this.company.contact.phone.trim();
      this.company.admin.firstName = this.company.admin.firstName.trim();
      this.company.admin.lastName = this.company.admin.lastName.trim();
      this.company.admin.email = this.company.admin.email.trim();
      this.company.admin.phone = this.company.admin.phone.trim();

      if (this.profileSave === 'FAIL') {
        const user = new CompanyAdminRequestModel();
        user.Firstname = this.company.admin.firstName.trim();
        user.Lastname = this.company.admin.lastName.trim();
        user.Email = this.company.admin.email;
        user.Password = await this.cryptionHelper.decryptText(this.encryptPwd);

        const result = await this.middlewareService.createCompanyAdmin(user);
        if (result.result === 'success') {
          const supplier = new SigninSupplierModel();
          supplier.Email = user.Email.trim();
          supplier.Password = user.Password.trim();

          const response = await this.middlewareService.signinSupplier(
            supplier
          );
          if (response.response_type === 'success') {
            sessionStorage.setItem('access_token', response.access_token);
          }
        }
      }
      let response = new companyResponseModel();
      if (invitationId) {
        response = await this.invitationService.updateSupplierAsync(
          invitationId,
          this.company
        );
      } else {
        response = await this.supplierService.updateSupplierAsync(this.company);
      }

      if (
        response.result === 'success' &&
        response.responseObject.companyName !== ''
      ) {
        store.commit('setCanNavigate', true);
        store.commit('setMyAccount', response.responseObject);
        store.commit('setEncryptedPwd', '');
        this.changedSettingsCounter = 0;
        this.changedSettings(this.changedSettingsCounter);
        this.profileSave = 'SUCCESS';
        this.isSaving = false;
        this.showSave = false;
        if (this.invitationId !== '' && this.invitationId !== null) {
          this.isAccountSave = true;
          this.isMainContactEmailDisabled = false;
        }
        await this.userService.loadUserInfoAsync(false);
        const user = this.$store.getters.user as UserModel;
        if (user.companyId === null || user.companyId === undefined) {
          const result = await this.client.getUserInfoAsync();
          this.$store.commit('setUser', result);
        }
        localStorage.setItem('invitationId', '');
        this.invitationId = '';
        this.companyClone = lodash.cloneDeep(this.company);
      } else if (
        response.result === 'fail' &&
        response.message ==
          'Error while saving account. Please try again with invitaion link'
      ) {
        NotificationHelper.createErrorNotification(
          'Your account update was not saved due to technical issues. Please click ‘Save’ again to successfully save your account details.'
        );
        this.profileSave = 'FAIL';
      }
    } finally {
      this.isSaving = false;
    }
  }

  private validateCompanyType(): void {
    this.updateStore();
    if (this.company.companyType === 0) {
      this.isValid = false;
      this.isValidType = false;
    } else {
      this.isValid = true;
      this.isValidType = true;
    }
    this.compareCompanyClone();
  }

  private companyPictureStateChanged(state: string): void {
    this.companyPicturesState = state;
  }

  private get getMobileAddreses(): AddressModel[] {
    const allAddresses: AddressModel[] = [];
    this.officeAddress.forEach((address) => {
      allAddresses.push(address);
    });
    this.factoryAddresses.forEach((address) => {
      allAddresses.push(address);
    });
    return allAddresses;
  }

  private countryStateCityValidation(): void {
    const officeAddresses = this.officeAddress;
    const factoryAddresses = this.factoryAddresses;
    let isInvalid = false;
    officeAddresses.forEach((o) => {
      if (
        (o.country == '' ||
          o.country == null ||
          o.state == '' ||
          o.state == null ||
          o.city == '' ||
          o.city == null) &&
        !this.isMobile &&
        o.id !== '00000000-0000-0000-0000-000000000000'
      ) {
        isInvalid = true;
      }
    });
    const invitationId = localStorage.getItem('invitationId');
    if (factoryAddresses.length > 0) {
      factoryAddresses.forEach((f) => {
        if (
          (f.country == '' ||
            f.country == null ||
            f.state == '' ||
            f.state == null ||
            f.city == '' ||
            f.city == null) &&
          !invitationId &&
          !this.isMobile &&
          f.id !== '00000000-0000-0000-0000-000000000000'
        ) {
          isInvalid = true;
        }
      });
    }
    this.isInvalidAddress = isInvalid;
  }

  private scrollToBottom(): void {
    if (!this.isMobile) {
      setTimeout(
        (app: any) => {
          const pageScroller = document.getElementById(
            'right-container'
          ) as HTMLElement;
          if (pageScroller) {
            pageScroller.scrollTop = pageScroller.scrollHeight;
          }
        },
        0,
        this
      );
    }
  }
  private checkValidation(brands: string): void {
    const chars = brands.split(',');
    if (chars.length > 5) {
      this.isBrandInvalid = true;
    } else {
      this.isBrandInvalid = false;
    }
    this.compareCompanyClone();
  }

  private toggleAddressStateToDisabled(
    index: number,
    addressType: string
  ): void {
    if (addressType === 'OFFICE') {
      if (
        this.officeAddress.length <= 1 ||
        this.officeAddress.filter(
          (o) => o.addressStatus === 'ENABLE' || o.addressStatus === ''
        ).length <= 1
      ) {
        this.showDisableSingleAddressWarning = true;
      } else {
        this.showDisableMultipleAddressWarning = true;
      }
      this.officeAddress[index].confirmError = false;
      this.officeAddress[index].lookupAvailable = false;
    }

    if (addressType === 'FACTORY') {
      if (
        this.factoryAddresses.length <= 1 ||
        this.factoryAddresses.filter(
          (o) => o.addressStatus === 'ENABLE' || o.addressStatus === ''
        ).length <= 1
      ) {
        this.showDisableSingleAddressWarning = true;
      } else {
        this.showDisableMultipleAddressWarning = true;
      }
      this.factoryAddresses[index].confirmError = false;
      this.factoryAddresses[index].lookupAvailable = false;
    }
    this.disableAddressIndex = index;
    this.disableAddressType = addressType;
  }

  private hideDisableAddressWarning(): void {
    this.disableAddressType = '';
    this.disableAddressIndex = -1;
    this.showDisableSingleAddressWarning = false;
    this.showDisableMultipleAddressWarning = false;
  }

  private disableAddressAndReOrder(): void {
    if (this.disableAddressType === 'OFFICE') {
      this.officeAddress[this.disableAddressIndex].addressStatus =
        AddressStatus.DISABLED;
    }
    if (this.disableAddressType === 'FACTORY') {
      this.factoryAddresses[this.disableAddressIndex].addressStatus =
        AddressStatus.DISABLED;
    }
    this.sortForDisabledAddresses(this.disableAddressType);
    this.hideDisableAddressWarning();
    this.compareAddressStatus();
  }

  private toggleAddressStateToEnabled(
    index: number,
    addressType: string,
    address: AddressModel
  ): void {
    if (addressType === 'OFFICE') {
      address.addressStatus = AddressStatus.ENABLE;
      this.officeAddress.splice(index, 1);
      this.officeAddress.splice(address.sequence, 0, address);
    }

    if (addressType === 'FACTORY') {
      address.addressStatus = AddressStatus.ENABLE;
      this.factoryAddresses.splice(index, 1);
      this.factoryAddresses.splice(address.sequence, 0, address);
    }
  }

  private sortForDisabledAddresses(addressType: string): void {
    if (addressType === 'OFFICE') {
      this.officeAddress = this.officeAddress.sort(
        (a: AddressModel, b: AddressModel) => {
          if (a.addressStatus === AddressStatus.DISABLED) {
            return 1;
          }
          if (b.addressStatus === AddressStatus.DISABLED) {
            return -1;
          }
          return 0;
        }
      );
    }

    if (addressType === 'FACTORY') {
      this.factoryAddresses = this.factoryAddresses.sort(
        (a: AddressModel, b: AddressModel) => {
          if (a.addressStatus === AddressStatus.DISABLED) {
            return 1;
          }
          if (b.addressStatus === AddressStatus.DISABLED) {
            return -1;
          }
          return 0;
        }
      );
    }
  }

  private removeAddress(addressType: string, addressIndex: number): void {
    if (addressType === 'OFFICE') {
      this.officeAddress.splice(addressIndex, 1);
    } else {
      this.factoryAddresses.splice(addressIndex, 1);
      this.company.factoryAddressDiffers =
        this.factoryAddresses.length === 0 ? false : true;
    }
  }

  private cancelChanges(): void {
    this.cityErrorList = {}
    this.countryErrorList = {}
    this.stateErrorList = {}
    this.isPhoneNonRoman = false;
    this.isLoading = true;
    this.company = this.companyClone;
    this.admin = this.company.admin;
    this.contact = this.company.contact;
    this.officeAddress = this.company.officeAddress;
    this.factoryAddresses = this.company.factoryAddresses;
    this.companyClone = lodash.cloneDeep(this.company);
    this.compareCompanyClone();
    setTimeout(
      (app: any) => {
        app.isLoading = false;
      },
      100,
      this
    );
    this.updateStore();
    this.allOfficeAddressConfirmed();
    this.allFactoryAddressConfirmed();
    this.allConfirmedAddress();
  }

  private compareCompanyClone(): void {
    if (
      this.invitationId === '' ||
      this.invitationId === null ||
      this.invitationId === undefined
    ) {
      let changes: number = 0;
      const company: [string, any][] = Object.entries(this.company).sort();
      const companyClone: [string, any][] = Object.entries(
        this.companyClone
      ).sort();

      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const getObjectDiff = (obj1: any, obj2: any) => {
        const obj1Props = Object.keys(obj1);
        const obj2Props = Object.keys(obj2);
        const keysWithDiffValue = obj1Props.reduce(
          (keysWithDiffValueAccumulator, key) => {
            // eslint-disable-next-line no-prototype-builtins
            const propExistsOnObj2 = obj2.hasOwnProperty(key);
            const hasNestedValue =
              obj1[key] instanceof Object && obj2[key] instanceof Object;
            const keyValuePairBetweenBothObjectsIsEqual =
              obj1[key] === obj2[key];

            if (!propExistsOnObj2) {
              if (
                obj1[key] !== null &&
                obj1[key] !== undefined &&
                obj1[key] !== '' &&
                obj1[key] instanceof Object
              ) {
                if (
                  obj1[key].city !== undefined &&
                  obj1[key].city !== null &&
                  obj1[key].city !== ''
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.1`);
                }
                if (
                  obj1[key].country !== undefined &&
                  obj1[key].country !== null &&
                  obj1[key].country !== ''
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.2`);
                }
                if (
                  obj1[key].state !== undefined &&
                  obj1[key].state !== null &&
                  obj1[key].state !== ''
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.3`);
                }

                if (
                  obj1[key].officeName !== undefined &&
                  obj1[key].officeName !== null &&
                  obj1[key].officeName !== ''
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.4`);
                }
                if (
                  obj1[key].line1 !== undefined &&
                  obj1[key].line1 !== null &&
                  obj1[key].line1 !== ''
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.5`);
                }
                if (
                  obj1[key].line2 !== undefined &&
                  obj1[key].line2 !== null &&
                  obj1[key].line2 !== ''
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.6`);
                }
                if (
                  obj1[key].postal !== undefined &&
                  obj1[key].postal !== null &&
                  obj1[key].postal !== ''
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.7`);
                }

                if (
                  obj1[key].companySubtypes !== undefined &&
                  obj1[key].companySubtypes !== null &&
                  obj1[key].companySubtypes.length > 0
                ) {
                  keysWithDiffValueAccumulator.push(`${key}.8`);
                }
              } else {
                keysWithDiffValueAccumulator.push(key);
              }
            } else if (hasNestedValue) {
              const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
              if (keyIndex >= 0) {
                keysWithDiffValueAccumulator.splice(keyIndex, 1);
              }
              const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
              for (const diff of nestedDiffs) {
                if (
                  obj1[key][0] &&
                  obj1[key][0] !== undefined &&
                  (obj1[key][0] == 'factoryAddressDiffers' ||
                    diff.endsWith('.disabled') ||
                    diff.endsWith('.forceEnabled') ||
                    diff.endsWith('.isOfficeAddress') ||
                    diff.endsWith('.isEdited') ||
                    diff.endsWith('.geoLat') ||
                    diff.endsWith('.geoLong'))
                ) {
                  continue;
                }
                keysWithDiffValueAccumulator.push(`${key}.${diff}`);
                if (
                  obj1[key][0] &&
                  obj1[key][0] !== undefined &&
                  (obj1[key][0] == 'categories' ||
                    obj1[key][0] == 'productGroups' ||
                    obj1[key][0] == 'companySubtypes')
                ) {
                  break;
                }
              }
            } else if (keyValuePairBetweenBothObjectsIsEqual) {
              const equalValueKeyIndex =
                keysWithDiffValueAccumulator.indexOf(key);
              keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
            }
            return keysWithDiffValueAccumulator;
          },
          obj2Props
        );

        return keysWithDiffValue;
      };
      const diffFound = getObjectDiff(company, companyClone);

      let newDiff: string[] = [];
      diffFound.filter((item: any) => {
        if (
          item.includes('isEdited') ||
          item.includes('lookupAvailable') ||
          item.includes('countryInFull') ||
          item.includes('stateError') ||
          item.includes('cityError')
        ) {
          return false;
        }
        if (item.includes('.companySubtypes.')) {
          item = item.slice(0, -2);
        }
        newDiff.push(item);
      });

      newDiff = newDiff.filter(
        (item, index) => newDiff.indexOf(item) === index
      );
      changes = newDiff.length;
      this.changedSettingsCounter = changes;
      this.changedSettings(changes);
      if (this.changedSettingsCounter > 0) {
        this.showSave = true;
      } else {
        this.showSave = false;
      }
    }
  }

  private compareAddressStatus(): void {
    let count = 0;
    this.companyClone.officeAddress.forEach((add) => {
      const address = this.officeAddress.filter((o) => o.id === add.id)[0];
      if (address.addressStatus !== add.addressStatus) {
        count += 1;
      }
    });
    this.companyClone.factoryAddresses.forEach((add) => {
      const address = this.factoryAddresses.filter((f) => f.id === add.id)[0];
      if (address.addressStatus !== add.addressStatus) {
        count += 1;
      }
    });
    this.changedSettingsCounter = this.changedSettingsCounter + count;
    this.changedSettings(this.changedSettingsCounter);
  }

  private async checkNonRomanChar(variableName:string,val:string){
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val);
  }

  private get confirmGeolocationPermission(): number {
    return this.invitationId !== null
      ? 1
      : this.userPermissions !== null
      ? this.userPermissions.confirmGeolocationCompany
      : 0;
  }

  private get uploadCertificatePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.uploadCertificates
      : 0;
  }

  private get uploadCompanyPicturePermission(): number {
    return this.invitationId !== null
      ? 1
      : this.userPermissions !== null
      ? this.userPermissions.createPhotos
      : 0;
  }

  private get isAdmin(): boolean {
    if (this.userRole === 'Editor' || this.userRole === 'Reader') {
      return false;
    } else {
      return true;
    }
  }

  private allowOnlyInteger(event: any, inputType: string): void {
    const regex = /^\d*$/;
    const inputValueString = String(event);
    if (!regex.test(inputValueString)) {
      if (inputType == 'employees') {
        this.company.employees = Number(inputValueString.replace(/[^\d]/g, ''));
      } else if (inputType == 'maleEmployees') {
        this.company.maleEmployees = Number(
          inputValueString.replace(/[^\d]/g, '')
        );
      } else if (inputType == 'feMaleEmployees') {
        this.company.femaleEmployees = Number(
          inputValueString.replace(/[^\d]/g, '')
        );
      } else if (inputType == 'turnover') {
        this.company.turnover = Number(inputValueString.replace(/[^\d]/g, ''));
      } else {
        this.company.monthlyCapacity = Number(
          inputValueString.replace(/[^\d]/g, '')
        );
      }
    }
  }

  @Emit()
  private changedSettings(value: number): void {}
}
