import { SupplierClient } from '@/clients/supplierClient';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { AddressModel } from '@/models/addressModel';
import { CompanyModel, CompanyNameModel, companyResponseModel } from '@/models/companyModel';
import { Guid } from '@/models/guid';
import { InvitedSupplierModel, LinkSupplierResponeModel } from '@/models/invitedSupplierModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { SignedComplianceDocResponse } from '@/models/signedComplianceDocResponse';
import { CompanyPictureModel, CompanyPictureReqModel } from '@/models/companyPictureModel';
import { SupplierEvidence } from '@/clients/supplierEvidence';
import { DelegateCompanyModel, OrderDelegateCompanyModel, OrderCompanyRespModel } from '@/models/delegateCompanyModel';

import store from '@/store';
import { TCMailModel } from '@/models/transcationCertificateModal';
import { SupplierBlockchain } from '@/clients/supplierBlockchain';

export class SupplierService {
  private readonly client: SupplierClient = new SupplierClient();
  private readonly supplier: SupplierEvidence = new SupplierEvidence();
  private readonly supplierBlockchain: SupplierBlockchain = new SupplierBlockchain();

  public async getSupplierAsync(): Promise<CompanyModel> {
    const result = await this.client.getSupplierAsync();
    store.commit('setProfile', result);
    store.commit('setMyAccount', result);
    return result;
  }

  public async downloadSignedPdf(
    data: any
  ): Promise<SignedComplianceDocResponse> {
    const result = await this.client.downloadSignedPdf(data);
    return result;
  }

  public async getSupplierNameByIdAsync(supplierId: string): Promise<string> {
    const result = await this.client.getSupplierNameByIdAsync(supplierId);
    return result;
  }

  public async getSupplyChainPartnersAsync(): Promise<OrderCompanyModel[]> {
    const result = await this.client.getSupplyChainPartnersAsync();
    store.commit('setSuppliers', result);
    return result;
  }

  public async updateSupplierAsync(
    supplier: CompanyModel
  ): Promise<companyResponseModel> {
    const response = await this.client.updateSupplierAsync(supplier);
    if (
      response.result === 'success' &&
      response.responseObject.companyName !== ''
    ) {
      // Only NoContent delivers no result
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.supplier_update_succeeded').toString()
      );
    }
    return response;
  }

  public async updateSupplierAddressAsync(
    supplierId: string,
    address: AddressModel
  ): Promise<string> {
    try {
      const result = await this.client.updateSupplierAddressAsync(
        supplierId,
        address
      );
      NotificationHelper.createSucceededNotification(
        i18n
          .t('global.notifications.supplier_address_update_succeeded')
          .toString()
      );
      return result;
    } catch {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return Guid.Empty;
    }
  }

  public async linkSupplierAsync(
    invitedSupplierModel: InvitedSupplierModel
  ): Promise<LinkSupplierResponeModel> {
    return await this.client.linkSupplierAsync(invitedSupplierModel);
  }

  public async uploadCompanyPicturesAsync(
    companyId: string,
    pictures: CompanyPictureModel[]
  ): Promise<void> {
    await this.supplier.uploadCompanyPicturesAsync(companyId, pictures);
  }

  public async submitCompanyPicturesAsync(
    pictures: CompanyPictureReqModel,
    companyId: string,
  ): Promise<Response> {
    const result = await this.supplier.submitCompanyPicturesAsync(pictures, companyId);
    if(result){
      return result;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return result;
    }
  }

  public async uploadProfilePictureAsync(
    userId: string,
    data: FormData
  ): Promise<boolean> {
    const result = await this.supplier.uploadProfilePictureAsync(userId, data);
    if (result) {
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.user_profile_upload').toString()
      );
      return true;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return false;
    }
  }

  public async updateProfilePictureAsync(
    userId: string,
    data: FormData
  ): Promise<boolean> {
    const result = await this.supplier.updateProfilePictureAsync(userId, data);
    if (result) {
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.user_profile_update').toString()
      );
      return true;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('errors.general').toString()
      );
      return false;
    }
  }

  public async deleteProfilePictureAsync(
    userId: string,
    profileId: string
  ): Promise<void> {
    const result = await this.supplier.deleteProfilePictureAsync(
      userId,
      profileId
    );
    NotificationHelper.createSucceededNotification(
      i18n.t('global.notifications.user_profile_delete').toString()
    );
  }

  public async deleteCompanyPictureAsync(
    companyId: string,
    pictureId: string
  ): Promise<void> {
    const result = await this.supplier.deleteCompanyPictureAsync(
      companyId,
      pictureId
    );
  }

  public async getSuppliersListAsync(supplierId: string): Promise<OrderCompanyModel[]> {
    const result = await this.supplier.getSuppliersListAsync(supplierId);
    store.commit('setSuppliers', result.supplier);
    return result.supplier;
  }

  // TTD-2422 delegate partner object save into ledger
  public async updateDelegatePartnerAsync(delegateCompany: OrderDelegateCompanyModel, supplierId: string): Promise<OrderCompanyRespModel> {
    const result = await this.supplier.updateDelegatePartnerAsync(delegateCompany, supplierId);
    return result;
  }

  // TTD-4486 sprint-24
  public async sendTCUploadMail( tcMailData: TCMailModel): Promise<Response> {
    return await this.client.sendTCUploadMail(tcMailData);
  }

  // TTD-3691 get company name
  public async getCompanyNameAsync(): Promise<CompanyNameModel[]>{
    const result = await this.supplierBlockchain.getCompanyNameAsync();
    if(result.success){
        const companies = JSON.parse(decodeURIComponent(escape(window.atob(result.companies))));
        return companies;
    }
    return [];
}
}
